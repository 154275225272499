import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appButtonType } from '@utils/globalConstant';
import { Button } from '@core/Inputs';
// import { Image } from '@core/Utils/Image';
import LogOutIcon from '@theme/assets/img/logout.svg';
import warning from '@theme/assets/img/warning.svg';
import swipe from '@theme/assets/img/swipe.svg';
import './style.scss';

export function Modal({
  isOpen,
  title,
  onClose,
  children,
  isCloseButton,
  modalClass,
  contentClass,
  web,
  md,
  sm,
  onLogout,
  titleClass,
  isDashboardModal,
  imageSource,
  screenSize,
  isUserLoggedIn
}) {
  // Contexts
  const { t } = useStaticContent();

  // Hooks
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  // modal should not be shown on /store-review page
  if (location.pathname === '/store-review') {
    return null;
  }

  return isOpen ? (
    <div
      className={`modal d-block fade show ${modalClass}${md || sm ? ' overlay-ipad' : ''}${
        web ? ' overlay-mobile' : ''
      }${isDashboardModal && screenSize > 1024 ? ' overlay-dashboard' : ''}`}>
      <div className="modal-dialog" role="document">
        {(md || sm) && (
          <div className="logout-icon-top">
            <div className="warning-icon pt-5">
              <img src={warning} alt="warning" />
              <img className="ms-2" src={swipe} alt="swipe" />
            </div>
            <h3
              className="text-center ipad-app-text"
              dangerouslySetInnerHTML={{ __html: t('ek_login_popup-app_copy1') }}
            />
          </div>
        )}
        <div className={`modal-content mx-auto ${contentClass}`}>
          {/* Only for showing the modal with image on the dashboard */}
          {isDashboardModal && (
            // <Image
            //   className="logo mx-100 modal-image"
            //   refs="ek_web_popup_new_tariff_image"
            //   resRefs="ek_web_popup_new_tariff_image"
            //   alt="sim card activation "
            //   // mobileStyle={{ height: '500', width: '200' }}
            //   screenSize={screenSize}
            // />
            <img className="modal-image" src={imageSource} alt="modal-image" />
          )}
          {isUserLoggedIn && (web || sm || md) && (
            <Button
              buttonClass={appButtonType.LINK}
              className="nav-link pe-0 logout nav-link"
              onClick={onLogout}>
              Logout{' '}
              <i>
                <img src={LogOutIcon} alt="appstore" />
              </i>
            </Button>
          )}

          {isDashboardModal && (
            <Button
              buttonClass={appButtonType.LINK}
              className="nav-link pe-0 logout nav-link"
              onClick={onLogout}>
              <u>{t('ek_login_popup_2_link')}</u>
            </Button>
          )}

          <div className="modal-main-content">
            <div className={`modal-header mx-auto${!title ? ' header-padding' : ''}`}>
              {isCloseButton && (
                <button type="button" className="close" aria-label="Close" onClick={onClose} />
              )}
              {title && (
                <h3
                  className={`modal-title mx-auto ${titleClass}`}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}
            </div>
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

Modal.propTypes = {
  // sx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  web: PropTypes.bool,
  md: PropTypes.bool,
  sm: PropTypes.bool,
  isCloseButton: PropTypes.bool,
  isDashboardModal: PropTypes.bool,
  imageSource: PropTypes.string,
  screenSize: PropTypes.string,
  title: PropTypes.string,
  modalClass: PropTypes.string,
  titleClass: PropTypes.string,
  contentClass: PropTypes.string,
  onClose: PropTypes.func,
  onLogout: PropTypes.func
};

Modal.defaultProps = {
  // sx: {},
  isOpen: false,
  web: false,
  md: false,
  sm: false,
  isCloseButton: false,
  isDashboardModal: false,
  imageSource: '',
  screenSize: '',
  title: '',
  modalClass: '',
  titleClass: '',
  contentClass: '',
  onClose: () => {},
  onLogout: () => {}
};

export default Modal;
