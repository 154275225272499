/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Alert } from '@core/Utils';

import { Loader } from '@utils/Icons/Loader';
import { Header } from './Header';
import { Footer } from './Footer';

export function withLayout(WrappedComponent) {
  // Wrap all of the components into one layout.

  function Layout(props) {
    // State
    const location = useLocation();

    if (
      location.pathname === '/activation' ||
      location.pathname === '/login' ||
      location.pathname === '/force-password-change' ||
      location.pathname === '/forgot-password' ||
      location.pathname === '/' ||
      location.pathname === '/store-review'
    ) {
      return (
        <main>
          <Loader />
          <Alert />
          <WrappedComponent {...props} />
        </main>
      );
    }

    return (
      <>
        <Header />
        <main className="dashboard-bg">
          <Loader />
          <Alert />
          <WrappedComponent {...props} />
        </main>

        <Footer />
      </>
    );
  }

  return Layout;
}

export default withLayout;
