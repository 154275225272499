import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
// import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useCustomer } from '@context/MobileOne';
import { useLayout, useLoader } from '@context/Utils';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { MetaTitle } from '@core/Layout/MetaTitle';
import {
  appAutoTopUpPeriodType,
  appButtonType,
  appIconsPath,
  appPaymentProductType,
  // appPaymentMethods,
  appRoute,
  appStorage,
  appTextInputClass,
  appTopUpTo,
  appTopUpType
} from '@utils/globalConstant';
import { Button, Radio, Text } from '@core/Inputs';
import { DirectPaymentProduct, PaymentMethod, PaymentProduct } from '@part/Payment';
import errorIcon from '@theme/assets/img/error.svg';
import { ChargeHeader } from '../../ChargeHeader';

import './style.scss';

export function SetDirectTopup() {
  // Constants

  // States

  // Contexts
  const { staticContentData, t } = useStaticContent();
  // const navigate = useNavigate();
  const { showLoader, hideLoader } = useLoader();
  const { isUserLoggedIn } = useAuth();

  const { isLoading: isStaticContentLoading } = useLayout();
  const {
    topUpForm,
    paymentProducts,
    // paymentMethod,
    allPaymentMethod,
    getPaymentProducts,
    getPaymentMethods,
    setTopUpForm,
    directTopupForm,
    directTopupFormInitialValue,
    TopupNumberValidation,
    onDirectTopupSubmit,
    directAmountValidation,
    setDirectTopupForm,
    directProducts,
    onStepTwoSubmit,
    onStepFourSubmit,
    paymentMethodForm,
    paymentValidations,
    deliveryAddress,
    onAutoTopupSubmit,
    alphaComToken,
    onSsoTokenCallWithQuery,
    isLoading: isOnlineTopUpLoading
  } = useOnlineTopUp();
  const { isLoading: isCustomerLoading, customerData } = useCustomer();

  // Functions
  const filterProduct = () =>
    (paymentProducts || [])
      .filter(
        (product) =>
          // Direct & Prepaid recharge
          (topUpForm.type === appTopUpType.DIRECT &&
            (product.productType === appTopUpType.DIRECT ||
              product.productType === appPaymentProductType.PREPAID)) ||
          // Low Balance Auto Topup
          (topUpForm.type === appTopUpType.AUTOMATIC &&
            product.productType === appPaymentProductType.LOW_BALANCE) // ||
      )
      .filter((product) => Number(product.id) !== 887189 && Number(product.id) !== 887525)
      .sort((a, b) => a.amount - b.amount);

  const filterPaymentMethod = () =>
    (allPaymentMethod || [])
      .filter(
        ({ paymentTokenEnabled, onceOffEnabled }) =>
          (topUpForm.type === appTopUpType.AUTOMATIC && paymentTokenEnabled) ||
          (topUpForm.type === appTopUpType.DIRECT && onceOffEnabled)
      )
      .sort((a, b) => {
        if (topUpForm.periodType === appAutoTopUpPeriodType.LOW_BALANCE) {
          const filterList = staticContentData?.ek_paymentMethodSortOrder?.autoTopUp || [];
          return filterList.indexOf(a.name) - filterList.indexOf(b.name);
        }
        const filterList = staticContentData?.ek_paymentMethodSortOrder?.directTopUp || [];
        return filterList.indexOf(a.name) - filterList.indexOf(b.name);
      });

  // Hooks
  useEffect(() => {
    setTopUpForm({ ...topUpForm, type: appTopUpType.DIRECT });
    getPaymentProducts();
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (isStaticContentLoading || isCustomerLoading || isOnlineTopUpLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isCustomerLoading, isOnlineTopUpLoading]);

  useEffect(() => {
    if (isUserLoggedIn && customerData && !alphaComToken) {
      onSsoTokenCallWithQuery();
    }
  }, [isUserLoggedIn, customerData]);

  // useEffect(() => {
  //   console.log('allPaymentMethod========================', allPaymentMethod);
  // }, [allPaymentMethod]);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_direct_topup_meta_title')}
        description={t('ek_direct_topup_meta_description')}
        keywords={t('ek_direct_topup_meta_keywords')}
      />

      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.CHARGES}>{t('ek_bc_charging')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.RECHARGE_CREDIT}>
              {t('ek_bc_charging_options')}
            </BreadcrumbItem>
            {/* <BreadcrumbItem>{t('ek_top-up_immediate')}</BreadcrumbItem> */}
          </Breadcrumbs>
          <h4 dangerouslySetInnerHTML={{ __html: t('ek_topup_step2_head_title_immi') }} />
          <br />
        </div>
      </div>

      <div className="row">
        <div className="card p-0 mt-5 mx-auto set-direct-topup">
          <div className="card-body">
            <ChargeHeader />
            <Formik
              initialValues={topUpForm}
              enableReinitialize
              validationSchema={TopupNumberValidation}
              onSubmit={onAutoTopupSubmit}>
              {({ values, handleChange, setValues, handleBlur, handleSubmit, errors, touched }) => (
                <Form onSubmit={handleSubmit}>
                  <h3
                    className="pt-4 pb-3"
                    dangerouslySetInnerHTML={{ __html: t('ek_voucher-sofort-top-up_h2') }}
                  />
                  <div
                    className="col-lg-12 pb-3"
                    aria-hidden
                    onClick={() => {
                      handleChange('isDiffNumberSelected')({
                        target: { name: 'isDiffNumberSelected', value: 'false' }
                      });
                    }}>
                    <Radio
                      inline
                      type="radio"
                      label={t('ek_topup_step2_nr_radio1')}
                      name="isDiffNumberSelected"
                      id="isDiffNumberSelected"
                      value="false"
                      isDefaultChecked={values.isDiffNumberSelected === 'false'}
                      // onChange={handleChange}
                    />
                  </div>

                  <div
                    className="col-lg-12 pb-3"
                    aria-hidden
                    onClick={() => {
                      handleChange('isDiffNumberSelected')({
                        target: { name: 'isDiffNumberSelected', value: 'true' }
                      });
                    }}>
                    <Radio
                      inline
                      type="radio"
                      value="true"
                      label={t('ek_topup_step2_nr_radio2')}
                      name="isDiffNumberSelected"
                      id="isDiffNumberSelected"
                      isDefaultChecked={values.isDiffNumberSelected === 'true'}
                      // onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-12 ps-4 ms-2">
                    <span
                      className="copytext number-label"
                      dangerouslySetInnerHTML={{ __html: t('ek_topup_step2_nr_txt') }}
                    />
                    <Text
                      type="text"
                      inputCustomClass={appTextInputClass.V2}
                      // label={t('ek_autotop-up_setup_text3')}
                      name="number"
                      id="number"
                      value={values.number}
                      placeholder={t('ek_topup_step2_nr_input')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={
                        touched.number && !errors.number && values.isDiffNumberSelected === 'true'
                      }
                      isInvalid={!!errors.number}
                      error={errors.number}
                      isDisabled={values.isDiffNumberSelected === 'false'}
                    />
                  </div>

                  <div className="d-block invalid-feedback ps-4 ms-2">
                    {errors.isDiffNumberSelected && (
                      <img className="error-icon" src={errorIcon} alt="error_icon" />
                    )}
                    {errors.isDiffNumberSelected}
                  </div>
                  {/* <div className="col-lg-12">
                      <div className="d-flex justify-content-between mt-5">
                        <Button
                          buttonClass={appButtonType.LINK}
                          role="button"
                          onClick={() => {
                            navigate(appRoute.RECHARGE_CREDIT);
                          }}>
                          {t('ek_change-back_button')}
                        </Button>

                        <Button
                          buttonClass={appButtonType.PRIMARY}
                          disabled={!isDiffNumberSelected}
                          isIcon
                          icon={<i className="bi bi-arrow-right" />}
                          type="submit">
                          {t('ek_autotop-up_setup_next')}
                        </Button>
                      </div>
                    </div> */}
                  {/* <h3>Welchen Betrag möchten Sie automatisch aufladen?</h3> */}
                  <h3
                    className="pt-2 pb-2"
                    dangerouslySetInnerHTML={{ __html: t('ek_topup_step2_topupprice_hdl') }}
                  />
                  <div className="row">
                    {filterProduct().map((item) =>
                      item.amount > 0 || Number(item.minAmount) === Number(item.maxAmount) ? (
                        <div
                          className={`col-lg-3 mb-3 ${item.developerProduct && 'pe-0'}`}
                          key={`select-payment-product-${item.id}`}>
                          <PaymentProduct
                            id={item.id}
                            amount={item.amount}
                            isSelected={values.productId === item.id}
                            onBlur={handleBlur}
                            onSelect={(id) => {
                              handleChange('productId')({
                                target: { name: 'productId', value: id }
                              });
                              handleChange('productType')({
                                target: { name: 'productType', value: item.productType }
                              });
                              handleChange('isDirectProduct')({
                                target: { name: 'isDirectProduct', value: 'NO' }
                              });
                              handleChange('productAmount')({
                                target: { name: 'productAmount', value: '' }
                              });
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          className="col-lg-12 pt-1 mb-5"
                          key={`select-direct-payment-product-${item.id}`}>
                          <DirectPaymentProduct
                            id={item.id}
                            isSelected={values.productId === item.id}
                            otherAmount={values.productAmount}
                            onBlur={handleBlur}
                            error={
                              touched.productAmount && errors.productAmount
                                ? errors.productAmount
                                : null
                            }
                            onSelect={(id) => {
                              setValues({
                                ...values,
                                productId: id,
                                productType: item.productType,
                                isDirectProduct: 'YES',
                                productAmount: item.minAmount
                              });
                              // // setValues({
                              // //   ...values,
                              // //   productId: id,
                              // //   isDirectProduct: 'YES'
                              // // });
                              // handleChange('productId')({
                              //   target: { name: 'productId', value: id }
                              // });
                              // handleChange('productType')({
                              //   target: { name: 'productType', value: item.productType }
                              // });
                              // handleChange('isDirectProduct')({
                              //   target: { name: 'isDirectProduct', value: 'YES' }
                              // });
                            }}
                            onAmountChange={(amount) => {
                              setValues({
                                ...values,
                                productId: item.id,
                                productType: item.productType,
                                isDirectProduct: 'YES',
                                productAmount: amount
                              });
                            }}
                          />
                        </div>
                      )
                    )}
                  </div>
                  <h3
                    className="py-2"
                    dangerouslySetInnerHTML={{ __html: t('ek_topup_step2_topuppay_hdl') }}
                  />
                  {/* <h3>Wie möchten Sie bezahlen?</h3> */}
                  {/* <p className="copytext-medium mb-4">{t('ek_autotop-up_setup_text9')}</p> */}
                  <div className="row">
                    {filterPaymentMethod().map((payment) => (
                      <div
                        className="col-lg-4 py-1"
                        aria-hidden
                        role="button"
                        key={`${payment.name}-method`}
                        onClick={() => {
                          // setFieldValue('paymentMethod', payment.name);
                          handleChange('paymentMethodName')({
                            target: { name: 'paymentMethodName', value: payment.name }
                          });
                        }}>
                        <PaymentMethod
                          name={payment.name}
                          onBlur={handleBlur}
                          // onSelect={(id) =>{
                          //   console.log("id", id);
                          //   handleChange('paymentMethod')({
                          //     target: { name: 'paymentMethod', value: id }
                          //   })
                          // }}
                          isSelected={values.paymentMethodName === payment.name}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="row pt-4">
                    <div className="col-lg-12 align-items-start">
                      <Radio
                        inline
                        type="radio"
                        label={t('ek_topup_step2_alphacom_txt')}
                        name="terms"
                        id="terms"
                        // value={true}
                        isDefaultChecked={values.terms}
                        onChange={handleChange}
                        onClick={() => {
                          handleChange('terms')({
                            target: { name: 'terms', value: !values.terms }
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="d-block invalid-feedback">
                        {errors.paymentMethod && (
                          <img className="error-icon" src={errorIcon} alt="error_icon" />
                        )}
                        {errors.paymentMethod}
                      </div>
                      <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                        <div className="pt-2 pb-4">
                          <img className="pb-4" src={appIconsPath.ALPHACOMM_LOGO} alt="alphacomm" />
                        </div>

                        {/* <Button
                            buttonClass={appButtonType.LINK}
                            type="button"
                            onClick={() => {
                              setAutoTopupForm({
                                ...autoTopupForm,
                                productId: '',
                                otherAmount: ''
                              });
                              sessionStorage.removeItem(appStorage.CART_NAME);
                              sessionStorage.removeItem(appStorage.TOPUP_TYPE);
                            }}>
                            {t('ek_change-back_button')}
                          </Button> */}
                        <Button
                          buttonClass={appButtonType.PRIMARY}
                          disabled={
                            isOnlineTopUpLoading ||
                            (values.chargeTo === appTopUpTo.OTHER && !values.phone) ||
                            !values.productId ||
                            // (values.productId && !values.productAmount) ||
                            !values.paymentMethodName ||
                            !values.terms ||
                            (errors &&
                              Object.keys(errors || {}).filter((v) => errors && errors && errors[v])
                                .length > 0)
                          }
                          isIcon
                          icon={<i className="bi bi-arrow-right" />}
                          type="submit">
                          {t('ek_topup_step2_btn')}
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-12 mt-5">
                          {touched.productId && errors.productId && (
                            <div className="d-block invalid-feedback">
                              {!!errors.productId && (
                                <img className="error-icon" src={errorIcon} alt="error_icon" />
                              )}
                              {errors.productId}
                            </div>
                          )}
                          <div className="d-flex justify-content-between mt-5">
                            <Button
                              buttonClass={appButtonType.LINK}
                              type="button"
                              onClick={() => {
                                setAutoTopupForm({
                                  ...autoTopupForm,
                                  periodDay: '',
                                  periodType: '',
                                  certainAmount: ''
                                });
                              }}
                            >
                              {t('ek_change-back_button')}
                            </Button>
                            <Button
                              buttonClass={appButtonType.PRIMARY}
                              disabled={!values.productId}
                              isIcon
                              icon={<i className="bi bi-arrow-right" />}
                              type="submit"
                            >
                              {t('ek_autotop-up_setup_next')}
                            </Button>
                          </div>
                        </div> */}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetDirectTopup;
