import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useCustomer } from '@context/MobileOne';
import { useLayout, useLoader } from '@context/Utils';
import {
  appAutoTopUpPeriodType,
  appButtonType,
  appIconsPath,
  appPaymentCertainDay,
  appPaymentMethods,
  appPaymentProductType,
  appRoute,
  appStorage,
  appTextInputClass,
  appTopUpTo,
  appTopUpType
} from '@utils/globalConstant';
import { Button, DropDown, Radio, Text } from '@core/Inputs';
import { DirectPaymentProduct, PaymentMethod, PaymentProduct } from '@part/Payment';
import errorIcon from '@theme/assets/img/error.svg';
import EmpfehLung from '@theme/assets/img/empfehlung.svg';
import { ChargeHeader } from '../../ChargeHeader';

import './style.scss';
import MoreInfoScreen from '@part/MoreInfoScreen';

export function SetAutoTopup() {
  // Constant

  // States
  const [selectedPeriodDay, setSelectedPeriodDay] = useState({});

  // Context
  const { t, staticContentData } = useStaticContent();
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useLoader();
  const { isUserLoggedIn } = useAuth();

  const { isLoading: isStaticContentLoading } = useLayout();
  const {
    topUpForm,
    TopupNumberValidation,
    directAmountValidation,
    autoTopupForm,
    onAutoTopupSubmit,
    setTopUpForm,
    setAutoTopupForm,
    autoTopupOptionValidation,
    paymentProducts,
    allPaymentProducts,
    setPaymentProducts,
    paymentMethod,
    paymentMethodForm,
    paymentValidations,
    onStepTwoSubmit,
    deliveryAddress,
    onStepFourSubmit,
    setIsAutoLowEnabled,
    isAutoLowEnabled,
    alphaComToken,
    onSsoTokenCallWithQuery,
    isLoading: isOnlineTopUpLoading,
    getPaymentProducts,
    getPaymentMethods,
    autoTopupMoreInfo,
    setAutoTopupMoreInfo
  } = useOnlineTopUp();
  const { isLoading: isCustomerLoading, customerData } = useCustomer();

  // Function

  const onChangeProductType = (value) => {
    if (
      allPaymentProducts.length > 0 &&
      (value === appAutoTopUpPeriodType.LOW_BALANCE || value === appAutoTopUpPeriodType.RATE)
    ) {
      let lowBalProducts = [];
      const fixedProducts = allPaymentProducts.filter(
        ({ productType, maxAmount, minAmount }) =>
          productType === appPaymentProductType.LOW_BALANCE && maxAmount === minAmount
      );

      const directProduct = allPaymentProducts.filter(
        ({ productType, maxAmount, minAmount }) =>
          productType === appPaymentProductType.LOW_BALANCE && maxAmount !== minAmount
      );

      lowBalProducts = fixedProducts;
      lowBalProducts.sort((x, y) => x.amount - y.amount);
      lowBalProducts.push(directProduct[0]);

      setPaymentProducts(lowBalProducts);
    }

    if (allPaymentProducts.length > 0 && value === appAutoTopUpPeriodType.ONCE_PER_MONTH) {
      let reccuringProducts = [];
      const fixedProducts = allPaymentProducts.filter(
        ({ productType, maxAmount, minAmount }) =>
          productType === appPaymentProductType.RECURRING && maxAmount === minAmount
      );

      const directProduct = allPaymentProducts.filter(
        ({ productType, maxAmount, minAmount }) =>
          productType === appPaymentProductType.RECURRING && maxAmount !== minAmount
      );

      reccuringProducts = fixedProducts;
      reccuringProducts.sort((x, y) => x.amount - y.amount);
      reccuringProducts.push(directProduct[0]);

      setPaymentProducts(reccuringProducts);
    }
  };

  const filterProduct = () =>
    (paymentProducts || [])
      .filter(
        (product) =>
          // Direct & Prepaid recharge
          (topUpForm.type === appTopUpType.DIRECT &&
            (product.productType === appTopUpType.DIRECT ||
              product.productType === appPaymentProductType.PREPAID)) ||
          // Low Balance Auto Topup
          (topUpForm.type === appTopUpType.AUTOMATIC &&
            product.productType === appPaymentProductType.LOW_BALANCE) // ||
      )
      .filter((product) => Number(product.id) !== 887189 && Number(product.id) !== 887525)
      .sort((a, b) => a.amount - b.amount);

  const filterPaymentMethod = () =>
    (paymentMethod || [])
      .filter(
        ({ paymentTokenEnabled, onceOffEnabled }) =>
          (topUpForm.type === appTopUpType.AUTOMATIC && paymentTokenEnabled) ||
          (topUpForm.type === appTopUpType.DIRECT && onceOffEnabled)
      )
      .sort((a, b) => {
        if (topUpForm.periodType === appAutoTopUpPeriodType.LOW_BALANCE) {
          const filterList = staticContentData?.ek_paymentMethodSortOrder?.autoTopUp || [];
          return filterList.indexOf(a.name) - filterList.indexOf(b.name);
        }
        const filterList = staticContentData?.ek_paymentMethodSortOrder?.directTopUp || [];
        return filterList.indexOf(a.name) - filterList.indexOf(b.name);
      });

  // Hooks
  useEffect(() => {
    setTopUpForm({ ...topUpForm, type: appTopUpType.AUTOMATIC });
    getPaymentProducts();
    getPaymentMethods();
  }, []);

  useEffect(() => {
    if (!autoTopupMoreInfo && staticContentData) {
      const moreInfoModal = document.querySelector('#auto-top-up-more-info');
      if (moreInfoModal) {
        moreInfoModal.addEventListener('click', () => {
          setAutoTopupMoreInfo(true);
        });
      }
    }
  }, [autoTopupMoreInfo, staticContentData]);

  useEffect(() => {
    if (isStaticContentLoading || isCustomerLoading || isOnlineTopUpLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isCustomerLoading, isOnlineTopUpLoading]);

  useEffect(() => {
    if (isUserLoggedIn && customerData && !alphaComToken) {
      onSsoTokenCallWithQuery();
    }
  }, [isUserLoggedIn, customerData]);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_auto_topup_meta_title')}
        description={t('ek_auto_topup_meta_description')}
        keywords={t('ek_auto_topup_meta_keywords')}
      />
      {autoTopupMoreInfo ? (
        <div className="row pt-4">
          <div className="col-lg-7 mx-auto">
            <MoreInfoScreen
              heading={t('ek_topup_overlay_title')}
              onBackClick={() => setAutoTopupMoreInfo(false)}>
              <div className="sreen-body">
                <div
                  className="copytext"
                  dangerouslySetInnerHTML={{ __html: t('ek_topup_overlay_txt') }}
                />
              </div>
            </MoreInfoScreen>
          </div>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-lg-12">
              <br />
              <Breadcrumbs>
                <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
                <BreadcrumbItem route={appRoute.CHARGES}>{t('ek_bc_charging')}</BreadcrumbItem>
                <BreadcrumbItem route={appRoute.RECHARGE_CREDIT}>
                  {t('ek_bc_charging_options')}
                </BreadcrumbItem>
              </Breadcrumbs>
              <h4 dangerouslySetInnerHTML={{ __html: t('ek_web_autotop-up_setup_h1') }} />
              <br />
            </div>
          </div>
          <div className="mt-4 pt-2">
            <div className="row">
              <div className="card p-0 mx-auto">
                <div className="card-body">
                  <ChargeHeader />
                  <Formik
                    initialValues={topUpForm}
                    enableReinitialize
                    validationSchema={TopupNumberValidation}
                    onSubmit={onAutoTopupSubmit}>
                    {({
                      values,
                      handleChange,
                      setValues,
                      handleBlur,
                      handleSubmit,
                      errors,
                      touched
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <h3
                          className="pt-4 pb-3"
                          dangerouslySetInnerHTML={{ __html: t('ek_topup_step2_hdl') }}
                        />
                        {/* <h3 className="pt-4 pb-3">Wann wird aufgeladen?</h3> */}
                        <p
                          className="copytext"
                          dangerouslySetInnerHTML={{ __html: t('ek_topup_step2_txt') }}
                        />
                        <h3
                          className=""
                          dangerouslySetInnerHTML={{ __html: t('ek_topup_step2_nr_hdl') }}
                        />
                        <div
                          className="col-lg-12 pb-3"
                          aria-hidden
                          onClick={() => {
                            handleChange('isDiffNumberSelected')({
                              target: { name: 'isDiffNumberSelected', value: 'false' }
                            });
                          }}>
                          <Radio
                            inline
                            type="radio"
                            label={t('ek_topup_step2_nr_radio1')}
                            name="isDiffNumberSelected"
                            id="isDiffNumberSelected"
                            value="false"
                            isDefaultChecked={values.isDiffNumberSelected === 'false'}
                            // onChange={handleChange}
                          />
                        </div>

                        <div
                          className="col-lg-12 pb-3"
                          aria-hidden
                          onClick={() => {
                            handleChange('isDiffNumberSelected')({
                              target: { name: 'isDiffNumberSelected', value: 'true' }
                            });
                          }}>
                          <Radio
                            inline
                            type="radio"
                            value="true"
                            label={t('ek_topup_step2_nr_radio2')}
                            name="isDiffNumberSelected"
                            id="isDiffNumberSelected"
                            isDefaultChecked={values.isDiffNumberSelected === 'true'}
                            // onChange={handleChange}
                          />
                        </div>

                        <div className="col-lg-12 ps-4 ms-2">
                          <span
                            className="copytext number-label"
                            dangerouslySetInnerHTML={{ __html: t('ek_topup_step2_nr_txt') }}
                          />
                          <Text
                            type="text"
                            inputCustomClass={appTextInputClass.V2}
                            // label={t('ek_autotop-up_setup_text3')}
                            name="number"
                            id="number"
                            value={values.number}
                            placeholder={t('ek_topup_step2_nr_input')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isValid={
                              touched.number &&
                              !errors.number &&
                              values.isDiffNumberSelected === 'true'
                            }
                            isInvalid={!!errors.number}
                            error={errors.number}
                            isDisabled={values.isDiffNumberSelected === 'false'}
                          />
                        </div>

                        <div className="d-block invalid-feedback ps-4 ms-2">
                          {errors.isDiffNumberSelected && (
                            <img className="error-icon" src={errorIcon} alt="error_icon" />
                          )}
                          {errors.isDiffNumberSelected}
                        </div>
                        {/* <div className="col-lg-12">
                      <div className="d-flex justify-content-between mt-5">
                        <Button
                          buttonClass={appButtonType.LINK}
                          role="button"
                          onClick={() => {
                            navigate(appRoute.RECHARGE_CREDIT);
                          }}>
                          {t('ek_change-back_button')}
                        </Button>

                        <Button
                          buttonClass={appButtonType.PRIMARY}
                          disabled={!isDiffNumberSelected}
                          isIcon
                          icon={<i className="bi bi-arrow-right" />}
                          type="submit">
                          {t('ek_autotop-up_setup_next')}
                        </Button>
                      </div>
                    </div> */}
                        {/* <h3>Welchen Betrag möchten Sie automatisch aufladen?</h3> */}
                        <h3
                          className="pt-2 pb-2"
                          dangerouslySetInnerHTML={{ __html: t('ek_topup_step2_topupprice_hdl') }}
                        />
                        <div className="row">
                          {filterProduct().map((item) =>
                            item.amount > 0 || Number(item.minAmount) === Number(item.maxAmount) ? (
                              <div className="col-lg-3" key={`select-payment-product-${item.id}`}>
                                <PaymentProduct
                                  id={item.id}
                                  amount={item.amount}
                                  isSelected={values.productId === item.id}
                                  onBlur={handleBlur}
                                  onSelect={(id) => {
                                    handleChange('productId')({
                                      target: { name: 'productId', value: id }
                                    });
                                    handleChange('productType')({
                                      target: { name: 'productType', value: item.productType }
                                    });
                                    handleChange('isDirectProduct')({
                                      target: { name: 'isDirectProduct', value: 'NO' }
                                    });
                                    handleChange('productAmount')({
                                      target: { name: 'productAmount', value: '' }
                                    });
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                className="col-lg-12 pt-1 mb-5"
                                key={`select-direct-payment-product-${item.id}`}>
                                <DirectPaymentProduct
                                  id={item.id}
                                  isSelected={values.productId === item.id}
                                  otherAmount={values.productAmount}
                                  onBlur={handleBlur}
                                  error={
                                    touched.productAmount && errors.productAmount
                                      ? errors.productAmount
                                      : null
                                  }
                                  onSelect={(id) => {
                                    setValues({
                                      ...values,
                                      productId: id,
                                      productType: item.productType,
                                      isDirectProduct: 'YES',
                                      productAmount: item.minAmount
                                    });
                                    // // setValues({
                                    // //   ...values,
                                    // //   productId: id,
                                    // //   isDirectProduct: 'YES'
                                    // // });
                                    // handleChange('productId')({
                                    //   target: { name: 'productId', value: id }
                                    // });
                                    // handleChange('productType')({
                                    //   target: { name: 'productType', value: item.productType }
                                    // });
                                    // handleChange('isDirectProduct')({
                                    //   target: { name: 'isDirectProduct', value: 'YES' }
                                    // });
                                  }}
                                  onAmountChange={(amount) => {
                                    setValues({
                                      ...values,
                                      productId: item.id,
                                      productType: item.productType,
                                      isDirectProduct: 'YES',
                                      productAmount: amount
                                    });
                                  }}
                                />
                              </div>
                            )
                          )}
                        </div>
                        <h3
                          className="py-2"
                          dangerouslySetInnerHTML={{ __html: t('ek_topup_step2_topuppay_hdl') }}
                        />
                        {/* <h3>Wie möchten Sie bezahlen?</h3> */}
                        {/* <p className="copytext-medium mb-4">{t('ek_autotop-up_setup_text9')}</p> */}
                        <div className="row">
                          {filterPaymentMethod().map((payment) => (
                            <div
                              className="col-lg-4 py-1"
                              aria-hidden
                              role="button"
                              key={`${payment.name}-method`}
                              onClick={() => {
                                // setFieldValue('paymentMethod', payment.name);
                                handleChange('paymentMethodName')({
                                  target: { name: 'paymentMethodName', value: payment.name }
                                });
                              }}>
                              <PaymentMethod
                                name={payment.name}
                                onBlur={handleBlur}
                                // onSelect={(id) =>{
                                //   console.log("id", id);
                                //   handleChange('paymentMethod')({
                                //     target: { name: 'paymentMethod', value: id }
                                //   })
                                // }}
                                isSelected={values.paymentMethodName === payment.name}
                              />
                            </div>
                          ))}
                          <div>
                            {values.paymentMethodName === appPaymentMethods.SEPA_DIRECT_DEBIT && (
                              <div
                                className="copytext"
                                dangerouslySetInnerHTML={{
                                  __html: t('ek_reg_step6_topuppay_sepa_active')
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="row pt-4">
                          <div className="col-lg-12 align-items-start">
                            <Radio
                              inline
                              type="radio"
                              label={t('ek_topup_step2_alphacom_txt')}
                              name="terms"
                              id="terms"
                              // value={true}
                              isDefaultChecked={values.terms}
                              onChange={handleChange}
                              onClick={() => {
                                handleChange('terms')({
                                  target: { name: 'terms', value: !values.terms }
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-12">
                            <div className="d-block invalid-feedback">
                              {errors.paymentMethod && (
                                <img className="error-icon" src={errorIcon} alt="error_icon" />
                              )}
                              {errors.paymentMethod}
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                              <div className="pt-2 pb-4">
                                <img
                                  className="pb-4"
                                  src={appIconsPath.ALPHACOMM_LOGO}
                                  alt="alphacomm"
                                />
                              </div>

                              {/* <Button
                            buttonClass={appButtonType.LINK}
                            type="button"
                            onClick={() => {
                              setAutoTopupForm({
                                ...autoTopupForm,
                                productId: '',
                                otherAmount: ''
                              });
                              sessionStorage.removeItem(appStorage.CART_NAME);
                              sessionStorage.removeItem(appStorage.TOPUP_TYPE);
                            }}>
                            {t('ek_change-back_button')}
                          </Button> */}
                              <Button
                                buttonClass={appButtonType.PRIMARY}
                                disabled={
                                  isOnlineTopUpLoading ||
                                  (values.chargeTo === appTopUpTo.OTHER && !values.phone) ||
                                  !values.productId ||
                                  // (values.productId && !values.productAmount) ||
                                  !values.paymentMethodName ||
                                  !values.terms ||
                                  (errors &&
                                    Object.keys(errors || {}).filter(
                                      (v) => errors && errors && errors[v]
                                    ).length > 0)
                                }
                                isIcon
                                icon={<i className="bi bi-arrow-right" />}
                                type="submit">
                                {t('ek_topup_step2_btn')}
                              </Button>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-lg-12 mt-5">
                          {touched.productId && errors.productId && (
                            <div className="d-block invalid-feedback">
                              {!!errors.productId && (
                                <img className="error-icon" src={errorIcon} alt="error_icon" />
                              )}
                              {errors.productId}
                            </div>
                          )}
                          <div className="d-flex justify-content-between mt-5">
                            <Button
                              buttonClass={appButtonType.LINK}
                              type="button"
                              onClick={() => {
                                setAutoTopupForm({
                                  ...autoTopupForm,
                                  periodDay: '',
                                  periodType: '',
                                  certainAmount: ''
                                });
                              }}
                            >
                              {t('ek_change-back_button')}
                            </Button>
                            <Button
                              buttonClass={appButtonType.PRIMARY}
                              disabled={!values.productId}
                              isIcon
                              icon={<i className="bi bi-arrow-right" />}
                              type="submit"
                            >
                              {t('ek_autotop-up_setup_next')}
                            </Button>
                          </div>
                        </div> */}
                      </Form>
                    )}
                  </Formik>

                  {/* {autoTopupForm.isDiffNumberSelected === '' && (
                <Formik
                  initialValues={autoTopupFormInitialValue}
                  enableReinitialize
                  validationSchema={TopupNumberValidation}
                  onSubmit={onAutoTopupSubmit}
                >
                  {({
                    values: { number, isDiffNumberSelected },
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    errors,
                    touched
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <h3
                        className="pt-4 pb-3 auto-topup-head"
                        dangerouslySetInnerHTML={{ __html: t('ek_autotop-up_setup_h2') }}
                      />

                      <div
                        className="col-lg-12 pb-3"
                        aria-hidden
                        onClick={() => {
                          handleChange('isDiffNumberSelected')({
                            target: { name: 'isDiffNumberSelected', value: 'false' }
                          });
                        }}
                      >
                        <Radio
                          inline
                          type="radio"
                          label={t('ek_autotop-up_setup_text1')}
                          name="isDiffNumberSelected"
                          id="selfNumber"
                          value="false"
                          isDefaultChecked={isDiffNumberSelected === 'false'}
                          onChange={handleChange}
                        />
                      </div>

                      <div
                        className="col-lg-12 pb-3"
                        aria-hidden
                        onClick={() => {
                          handleChange('isDiffNumberSelected')({
                            target: { name: 'isDiffNumberSelected', value: 'true' }
                          });
                        }}
                      >
                        <Radio
                          inline
                          type="radio"
                          value="true"
                          label={t('ek_autotop-up_setup_text2')}
                          name="isDiffNumberSelected"
                          id="diffNumber"
                          isDefaultChecked={isDiffNumberSelected === 'true'}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-lg-12 ps-4 ms-2">
                        <span className="copytext number-label">
                          {t('ek_autotop-up_setup_text3')}
                        </span>
                        <Text
                          type="text"
                          inputCustomClass={appTextInputClass.V2}
                          // label={t('ek_autotop-up_setup_text3')}
                          name="number"
                          id="phoneNumber"
                          value={number}
                          placeholder={t('ek_autotop-up_nr-input')}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={
                            touched.number && !errors.number && isDiffNumberSelected === 'true'
                          }
                          isInvalid={!!errors.number}
                          error={errors.number}
                          isDisabled={isDiffNumberSelected === 'false'}
                        />
                      </div>

                      <div className="d-block invalid-feedback ps-4 ms-2">
                        {errors.isDiffNumberSelected && (
                          <img className="error-icon" src={errorIcon} alt="error_icon" />
                        )}
                        {errors.isDiffNumberSelected}
                      </div>
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-between mt-5">
                          <Button
                            buttonClass={appButtonType.LINK}
                            role="button"
                            onClick={() => {
                              navigate(appRoute.RECHARGE_CREDIT);
                            }}
                          >
                            {t('ek_change-back_button')}
                          </Button>

                          <Button
                            buttonClass={appButtonType.PRIMARY}
                            disabled={!isDiffNumberSelected}
                            isIcon
                            icon={<i className="bi bi-arrow-right" />}
                            type="submit"
                          >
                            {t('ek_autotop-up_setup_next')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}

              {autoTopupForm.isDiffNumberSelected !== '' && autoTopupForm.periodType === '' && (
                <Formik
                  initialValues={autoTopupFormInitialValue}
                  validationSchema={autoTopupOptionValidation}
                  onSubmit={onAutoTopupSubmit}
                >
                  {({ values: { periodType }, handleChange, handleSubmit, errors }) => (
                    <Form className="auto-top-radio-option" onSubmit={handleSubmit}>
                      <h3 className="pt-4 pb-4 auto-topup-head">{t('ek_autotop-up_setup_h3')}</h3>

                      <div
                        aria-hidden
                        onClick={() => {
                          handleChange('periodType')({
                            target: {
                              name: 'periodType',
                              value: appAutoTopUpPeriodType.LOW_BALANCE
                            }
                          });
                          onChangeProductType(appAutoTopUpPeriodType.LOW_BALANCE);
                          setIsAutoLowEnabled(false);
                        }}
                      >
                        <div className="col-lg-12">
                          <div className="d-flex align-items-center pb-4">
                            <span className="copytext-semibold">
                              {t('ek_autotop-up_setup_option1')}
                            </span>

                            <div className="welcome-badge listing-badge d-flex ms-3">
                              <img src={EmpfehLung} alt="Icon" />{' '}
                              <h6 className="mb-0 px-2">
                                {t('ek_autotop-up_setup_recommendation')}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="col-lg-6 ps-1">
                            <span className="copytext">{t('ek_autotop-up_setup_text4')}</span>
                          </div>
                          <div className="col-lg-1 ps-2">
                            <Radio
                              reverse
                              type="radio"
                              name="periodType"
                              id={appAutoTopUpPeriodType.LOW_BALANCE}
                              value={appAutoTopUpPeriodType.LOW_BALANCE}
                              isDefaultChecked={periodType === appAutoTopUpPeriodType.LOW_BALANCE}
                              onChange={(e) => {
                                onChangeProductType(e);
                                handleChange('periodType')(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <p
                          className="copytext-semibold ms-3 mt-4"
                          dangerouslySetInnerHTML={{ __html: t('ek_autotop-up_text6') }}
                        />
                      </div>
                      <div
                        aria-hidden
                        onClick={() => {
                          handleChange('periodType')({
                            target: {
                              name: 'periodType',
                              value: appAutoTopUpPeriodType.LOW_BALANCE
                            }
                          });
                          onChangeProductType(appAutoTopUpPeriodType.LOW_BALANCE);
                          setIsAutoLowEnabled(true);
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="col-lg-10 ps-1">
                            <span className="copytext">{t('ek_autotop-up_setup_text6')}</span>
                          </div>
                          <div className="col-lg-1 ps-2">
                            <Radio
                              reverse
                              type="radio"
                              // name="periodType"
                              id={appAutoTopUpPeriodType.LOW_BALANCE}
                              value={isAutoLowEnabled}
                              isDefaultChecked={
                                periodType === appAutoTopUpPeriodType.LOW_BALANCE &&
                                isAutoLowEnabled
                              }
                              onChange={(e) => {
                                onChangeProductType(e);
                                handleChange('periodType')(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        aria-hidden
                        onClick={() => {
                          handleChange('periodType')({
                            target: {
                              name: 'periodType',
                              value: appAutoTopUpPeriodType.ONCE_PER_MONTH
                            }
                          });
                          onChangeProductType(appAutoTopUpPeriodType.ONCE_PER_MONTH);
                        }}
                      >
                        <div className="col-lg-12 pt-3">
                          <span className="copytext-semibold">
                            {t('ek_autotop-up_setup_option2')}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="col-lg-3 ps-1">
                            <span className="copytext">{t('ek_autotop-up_setup_text7')}</span>
                          </div>
                          <div className="col-lg-3">
                            <DropDown
                              label={t('ek_autotop-up_setup_days')}
                              id="periodDay"
                              name="periodDay"
                              apiData={appPaymentCertainDay.map((value) => ({
                                id: `${value}`,
                                value: `${value}`
                              }))}
                              setSelectedItem={(e) => {
                                setSelectedPeriodDay(e);
                                handleChange({
                                  target: {
                                    id: 'periodDay',
                                    name: 'periodDay',
                                    value: e.id
                                  }
                                });
                              }}
                              selectedItem={selectedPeriodDay}
                              error={errors.periodDay}
                            />
                          </div>
                          <div className="col-lg-4 ps-1">
                            <span className="copytext">{t('ek_autotop-up_setup_text8')}</span>
                          </div>
                          <div className="col-lg-1 ps-2">
                            <Radio
                              reverse
                              type="radio"
                              isDefaultChecked={
                                periodType === appAutoTopUpPeriodType.ONCE_PER_MONTH
                              }
                              name="periodType"
                              id={appAutoTopUpPeriodType.ONCE_PER_MONTH}
                              value={appAutoTopUpPeriodType.ONCE_PER_MONTH}
                              onChange={(e) => {
                                onChangeProductType(e);
                                handleChange('periodType')(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-12"
                        aria-hidden
                        onClick={() => {
                          handleChange('periodType')({
                            target: { name: 'periodType', value: appAutoTopUpPeriodType.RATE }
                          });
                          onChangeProductType(appAutoTopUpPeriodType.RATE);
                          setIsAutoLowEnabled(true);
                        }}
                      >
                        <div className="col-lg-12 pt-3 pb-3">
                          <span className="copytext-semibold">
                            {t('ek_autotop-up_setup_option3')}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="copytext pe-2 ps-1">{t('ek_autotop-up_setup_text6')}</p>
                          <div className="set-auto-opt2">
                            <Radio
                              reverse
                              type="radio"
                              name="periodType"
                              id="selfNumber"
                              isDefaultChecked={periodType === appAutoTopUpPeriodType.RATE}
                              value={appAutoTopUpPeriodType.RATE}
                              onChange={(e) => {
                                onChangeProductType(e);
                                handleChange('periodType')(e);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <br />
                      <div className="col-lg-12">
                        <div className="d-block invalid-feedback">
                          {!!errors.periodType && (
                            <img className="error-icon" src={errorIcon} alt="error_icon" />
                          )}
                          {errors.periodType}
                        </div>
                        <div className="d-flex justify-content-between">
                          <Button
                            buttonClass={appButtonType.LINK}
                            type="button"
                            onClick={() => {
                              // setSelectedCertainAmount({});
                              setSelectedPeriodDay({});
                              setAutoTopupForm({
                                ...autoTopupForm,
                                isDiffNumberSelected: '',
                                number: ''
                              });
                            }}
                          >
                            {t('ek_change-back_button')}
                          </Button>
                          <Button
                            buttonClass={appButtonType.PRIMARY}
                            disabled={!periodType}
                            isIcon
                            icon={<i className="bi bi-arrow-right" />}
                            type="submit"
                          >
                            {t('ek_autotop-up_setup_next')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
              {autoTopupForm.isDiffNumberSelected !== '' &&
                autoTopupForm.periodType !== '' &&
                autoTopupForm.productId === '' && (
                  <Formik
                    initialValues={autoTopupForm}
                    validationSchema={directAmountValidation}
                    validateOnMount
                    onSubmit={onStepTwoSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleBlur,
                      setValues,
                      handleSubmit,
                      errors,
                      touched
                    }) => (
                      <Form className="set-auto-pricing" onSubmit={handleSubmit}>
                        <br />
                        <h3
                          className="pt-2 pb-2"
                          dangerouslySetInnerHTML={{ __html: t('ek_autotop-up_setup_h4') }}
                        />
                        <br />
                        <div className="row">
                          {paymentProducts.map((item) =>
                            item.amount > 0 || Number(item.minAmount) === Number(item.maxAmount) ? (
                              <div className="col-lg-3" key={`select-payment-product-${item.id}`}>
                                <PaymentProduct
                                  id={item.id}
                                  amount={item.amount}
                                  isSelected={values.productId === item.id}
                                  onBlur={handleBlur}
                                  onSelect={(id) => {
                                    handleChange('productId')({
                                      target: { name: 'productId', value: id }
                                    });
                                    handleChange('productType')({
                                      target: { name: 'productType', value: item.productType }
                                    });
                                    handleChange('isDirectProduct')({
                                      target: { name: 'isDirectProduct', value: 'NO' }
                                    });
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                className="col-lg-12 pt-1 mb-5"
                                key={`select-direct-payment-product-${item.id}`}
                              >
                                <DirectPaymentProduct
                                  id={item.id}
                                  isSelected={values.productId === item.id}
                                  otherAmount={values.otherAmount}
                                  onBlur={handleBlur}
                                  error={
                                    touched.otherAmount && errors.otherAmount
                                      ? errors.otherAmount
                                      : null
                                  }
                                  onSelect={(id) => {
                                    // setValues({
                                    //   ...values,
                                    //   productId: id,
                                    //   isDirectProduct: 'YES'
                                    // });
                                    handleChange('productId')({
                                      target: { name: 'productId', value: id }
                                    });
                                    handleChange('productType')({
                                      target: { name: 'productType', value: item.productType }
                                    });
                                    handleChange('isDirectProduct')({
                                      target: { name: 'isDirectProduct', value: 'YES' }
                                    });
                                  }}
                                  onAmountChange={(amount) => {
                                    setValues({
                                      ...values,
                                      productId: item.id,
                                      isDirectProduct: 'YES',
                                      otherAmount: amount
                                    });
                                  }}
                                />
                              </div>
                            )
                          )}
                        </div>

                        <div className="col-lg-12 mt-5">
                          {touched.productId && errors.productId && (
                            <div className="d-block invalid-feedback">
                              {!!errors.productId && (
                                <img className="error-icon" src={errorIcon} alt="error_icon" />
                              )}
                              {errors.productId}
                            </div>
                          )}
                          <div className="d-flex justify-content-between mt-5">
                            <Button
                              buttonClass={appButtonType.LINK}
                              type="button"
                              onClick={() => {
                                setAutoTopupForm({
                                  ...autoTopupForm,
                                  periodDay: '',
                                  periodType: '',
                                  certainAmount: ''
                                });
                              }}
                            >
                              {t('ek_change-back_button')}
                            </Button>
                            <Button
                              buttonClass={appButtonType.PRIMARY}
                              disabled={!values.productId}
                              isIcon
                              icon={<i className="bi bi-arrow-right" />}
                              type="submit"
                            >
                              {t('ek_autotop-up_setup_next')}
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              {autoTopupForm.productId !== '' && deliveryAddress.email !== '' && (
                <Formik
                  initialValues={paymentMethodForm}
                  enableReinitialize
                  validationSchema={paymentValidations}
                  onSubmit={onStepFourSubmit}
                >
                  {({ values, handleChange, handleBlur, setFieldValue, handleSubmit, errors }) => (
                    <Form onSubmit={handleSubmit}>
                      <br />
                      <h3>{t('ek_autotop-up_setup_h5')}</h3>
                      <br />
                      <p className="copytext-medium mb-4">{t('ek_autotop-up_setup_text9')}</p>
                      <div className="row">
                        {paymentMethod.map(
                          (payment) => (
                            <div
                              className="col-lg-4 py-1"
                              aria-hidden
                              role="button"
                              key={`${payment.name}-method`}
                              onClick={() => {
                                setFieldValue('paymentMethod', payment.name);
                              }}
                            >
                              <PaymentMethod
                                name={payment.name}
                                onBlur={handleBlur}
                                onSelect={(id) =>
                                  handleChange('paymentMethod')({
                                    target: { name: 'paymentMethod', value: id }
                                  })
                                }
                                isSelected={values.paymentMethod === payment.name}
                              />
                            </div>
                          )
                        )}
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <div className="d-block invalid-feedback">
                            {errors.paymentMethod && (
                              <img className="error-icon" src={errorIcon} alt="error_icon" />
                            )}
                            {errors.paymentMethod}
                          </div>
                          <div className="d-flex justify-content-between mt-5">
                            <Button
                              buttonClass={appButtonType.LINK}
                              type="button"
                              onClick={() => {
                                setAutoTopupForm({
                                  ...autoTopupForm,
                                  productId: '',
                                  otherAmount: ''
                                });
                                sessionStorage.removeItem(appStorage.CART_NAME);
                                sessionStorage.removeItem(appStorage.TOPUP_TYPE);
                              }}
                            >
                              {t('ek_change-back_button')}
                            </Button>
                            <Button
                              buttonClass={appButtonType.PRIMARY}
                              disabled={!values.paymentMethod}
                              isIcon
                              icon={<i className="bi bi-arrow-right" />}
                              type="submit"
                            >
                              {t('ek_autotop-up_setup_next')}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SetAutoTopup;
