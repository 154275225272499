import React from 'react';
import { useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import EDEKAsmartLogo from '@theme/assets/img/EDEKAsmart_logo_neg.svg';
import { deviceRegex, appRoute } from '@utils/globalConstant';

export function RateAppRedirect() {
  const { t } = useStaticContent();

  useEffect(() => {
    // const androidStoreUrl = t('ek_redirect_android_store_url');
    // const iosStoreUrl = t('ek_redirect_ios_store_url');

    // REFACTOR: Had issues with loading links from the CMS, so using hardcoded URLs for now
    const androidStoreUrl = 'https://play.google.com/store/apps/details?id=de.edekasmart.cscapp';
    const iosStoreUrl = 'https://apps.apple.com/app/id1342660332?action=write-review';
    const userAgent = navigator.userAgent;

    if (deviceRegex.ISANDRIOD.test(userAgent)) {
      window.location.href = androidStoreUrl;
      // MMSstream check is for IE and Edge on Windows devices
    } else if (deviceRegex.ISIOS.test(userAgent) && !window.MSStream) {
      window.location.href = iosStoreUrl;
    } else {
      window.location.href = appRoute.LANDING_PAGE;
    }
  }, []);
  return (
    <>
      <div className="light-gradient h-100 w-100 vh-100">
        <div className="container">
          <div className="sticky-top">
            <div className="logo">
              <a href={appRoute.LANDING_PAGE}>
                <img src={EDEKAsmartLogo} alt="Edeka Smart" />
              </a>
            </div>
          </div>
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ height: 'calc(100vh - 232px)' }}>
            {/* REFACTOR: h1 and p text is hardcoded for now to avoid showing language keys from the CMS during the redirect */}
            <h1 className="text-uppercase text-center">Vielen Dank für Ihr Feedback!</h1>
            <p className="copytext-medium text-center pt-5 mb-0">
              Mit dieser Rückmeldung helfen Sie uns, die App kontinuierlich weiterzuentwickeln und
              zu verbessern.
            </p>
            {/* <h1
              className="text-uppercase text-center"
              dangerouslySetInnerHTML={{ __html: t('ek_redirect_store_review_heading') }}
            /> */}
            {/* <p
              className="copytext-medium text-center pt-5 mb-0"
              dangerouslySetInnerHTML={{
                __html: t('ek_redirect_store_review_text')
              }}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default RateAppRedirect;
