/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import unlimited from '@theme/assets/img/unlimited.svg';
import './style.scss';

import {
  appPassTypeID,
  appButtonType,
  appRoute,
  appTariffs,
  appUsageType,
  counterTDMArray,
  getMaxValue,
  dateTimeDifferenceCalculator
} from '@utils/globalConstant';
import { Button } from '@core/Inputs';

export function PackDetailCard({ item, tariff }) {
  // Constants
  let leftDay = null;
  const isData = appUsageType.DATA === item?.type || appUsageType.DATA === item?.counterType;
  const isMinute = appUsageType.VOICE === item?.type || appUsageType.VOICE === item?.counterType;
  const isSms = appUsageType.SMS === item?.type || appUsageType.SMS === item?.counterType;
  const isTalkTariff = appTariffs.TALK === tariff?.id;

  const usages = item;
  const expairyData = usages && usages.expiryDateTime && usages?.expiryDateTime.split(' ')[0];

  // const date2 = moment(expairyData, 'DD.MM.YYYY').format('YYYY-MM-DD');
  // const date1 = moment();
  // // leftDay =
  //   date1.diff(date2, 'days') >= 0 ? date1.diff(date2, 'days') : -1 * date1.diff(date2, 'days');

  // taken over edeka-app implementation (2024-12-11)
  // Convert the expiry date to a moment object
  let date2 = moment(moment(expairyData, 'DD.MM.YYYY').format('YYYY-MM-DD'));
  // Get the current date
  const today = new Date();
  // Convert the current date to a moment object
  let date1 = moment(moment(today).format('yyyy-MM-DD'));
  // Calculate the number of days left until the expiry date
  leftDay = date2.diff(date1, 'days');
  let leftTime = `${dateTimeDifferenceCalculator(usages?.expiryDateTime)?.value}`;
  let leftHours = `${dateTimeDifferenceCalculator(usages?.expiryDateTime)?.hoursValue}`;
  let leftMins = `${dateTimeDifferenceCalculator(usages?.expiryDateTime)?.minutesValue}`;

  // Contexts
  const { t } = useStaticContent();
  const navigate = useNavigate();
  const {
    tariffDetails: { additionalInfo: { primaryColor = '#fffff' } = {} },
    isFlatTariff
  } = useDashBoard();

  // Functions
  // const getUsedUsage = (usage) => `${usage?.remainingValue} ${usage?.remainingUnit}`;
  const getUsedUsage = (usage) => {
    if (usages?.remainingValue?.includes('.')) {
      usages.remainingValue = parseFloat(usages?.remainingValue).toFixed(2);
    }
    if (usages?.remainingUnit === 'MB' && usages?.initialUnit !== 'MB') {
      usages.remainingValue = usages?.remainingValue / 1024;
      usages.remainingValue = parseFloat(usages?.remainingValue).toFixed(2);
      usages.remainingUnit = 'GB';
    }
    return `${usage?.remainingValue} ${usage?.remainingUnit}`;
  };

  const getTotalValue = (total) =>
    `${t('ek_dashboard_left_data').replace(
      'total_value',
      `${total?.initialValue} ${total?.initialUnit}`
    )}`;

  const expiryDate = () => {
    const endDate =
      Number(leftDay) > 1
        ? t('ek_dashboard_left_days').replace('left_days', leftDay)
        : t('ek_dashboard_left_day').replace('left_days', leftDay);
    return endDate;
  };

  return (
    <div className="tarif-data-slide" border="primary">
      <div className="text-center pb-3 pt-1">
        <h4 className="text-center pb-3">
          {item?.title && item?.title !== counterTDMArray[0].title
            ? t(item?.title)
            : (item?.name?.includes('Ihr')
                ? item?.name?.replace('Ihr Datenvolumen', 'Datenvolumen')
                : item?.name) || t(item?.title)}
        </h4>
      </div>

      <div className="tarif-data-slide-inner">
        {usages && Object.keys(usages).length > 0 ? (
          // eslint-disable-next-line eqeqeq
          usages.initialValue == 0 ? (
            isFlatTariff && !isData ? (
              <div className="circularprogress">
                <CircularProgressbarWithChildren
                  minValue={0}
                  maxValue={100}
                  value={0}
                  strokeWidth={3}
                  styles={buildStyles({
                    pathColor: primaryColor
                  })}>
                  <div className="justify-content-center text-center">
                    <h1>{t('ek_dashboard_flatrate')}</h1>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            ) : isData ? (
              <div className="circularprogress">
                <CircularProgressbarWithChildren
                  minValue={0}
                  maxValue={100}
                  strokeWidth={3}
                  value={isTalkTariff ? 100 : 0}
                  styles={buildStyles({
                    pathColor: primaryColor
                  })}>
                  <div className="justify-content-center text-center">
                    <h6 className="font-blue" style={{ fontSize: '14px' }}>
                      {t('ek_dashboard_no_data_booked')}
                    </h6>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            ) : (
              <div className="circularprogress">
                <CircularProgressbarWithChildren
                  minValue={0}
                  maxValue={100}
                  strokeWidth={3}
                  value={100}
                  styles={buildStyles({
                    pathColor: primaryColor
                  })}>
                  <div className="justify-content-center text-center">
                    {isMinute && (
                      <>
                        <h1 className="font-blue ps-2">{t('ek2_dashboard_talk_min_price')}</h1>
                        <h6 className="font-blue">{t('ek2_dashboard_talk_min')}</h6>
                        <div className="font-blue">{t('ek2_dashboard_talk_net')}</div>
                      </>
                    )}
                    {isSms && (
                      <>
                        <h1 className="font-blue ps-2">{t('ek2_dashboard_talk_sms_price')}</h1>
                        <h6 className="font-blue">{t('ek2_dashboard_talk_sms')}</h6>
                        <div className="font-blue">{t('ek2_dashboard_talk_net')}</div>
                      </>
                    )}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            )
          ) : (
            <div className="circularprogress">
              <CircularProgressbarWithChildren
                minValue={0}
                maxValue={100}
                strokeWidth={3}
                className={
                  usages?.passType === appPassTypeID.DAY_FLAT_UNLIMITED ? 'unlimited-card' : ''
                }
                value={
                  usages?.passType == appPassTypeID.DAY_FLAT_UNLIMITED
                    ? 100
                    : (getMaxValue(usages).currValue / getMaxValue(usages).maximumValue) * 100 || 0
                }
                styles={buildStyles({
                  pathColor: primaryColor
                })}>
                {usages?.initialValue && usages?.passType !== appPassTypeID.DAY_FLAT_UNLIMITED ? (
                  <div className="justify-content-center text-center circlular-progress-inner">
                    {usages?.remainingValue == null ? (
                      <>
                        <div className="justify-content-center text-center">
                          <h6 className="font-blue" style={{ fontSize: '16px' }}>
                            {t('ek2_dashboard_no_data_volumen')}
                          </h6>
                        </div>
                      </>
                    ) : (
                      <>
                        <h6 className="text-center">{getUsedUsage(item)}</h6>
                        <div
                          className="font-blue"
                          dangerouslySetInnerHTML={{ __html: getTotalValue(item) }}
                        />
                      </>
                    )}
                    {/* {leftDay ? (
                      <div
                        className="details-expiry"
                        dangerouslySetInnerHTML={{ __html: expiryDate() }}
                      />
                    ) : null} */}
                    {leftTime ? (
                      <>
                        {leftTime <= 0 && leftHours <= 0 && leftMins <= 0 ? (
                          <div
                            className="details-expiry px-1"
                            dangerouslySetInnerHTML={{ __html: t('ek2_dashboard_time_over') }}
                          />
                        ) : (
                          <>
                            <div
                              className="details-expiry px-1"
                              dangerouslySetInnerHTML={{ __html: t('ek2_dashboard_left_day') }}
                            />
                            {leftTime <= 0 ? (
                              leftHours <= 0 ? (
                                <div
                                  className="details-expiry px-1"
                                  dangerouslySetInnerHTML={{
                                    __html: `${leftMins} ${leftMins > 1 ? 'Minuten' : 'Minute'}`
                                  }}
                                />
                              ) : (
                                <div
                                  className="details-expiry px-1"
                                  dangerouslySetInnerHTML={{
                                    __html: `${leftHours} ${leftHours > 1 ? 'Stunden' : 'Stunde'}`
                                  }}
                                />
                              )
                            ) : (
                              <div
                                className="details-expiry px-1"
                                dangerouslySetInnerHTML={{
                                  __html: `${leftTime} ${leftTime > 1 ? 'Tage' : 'Tag'}`
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                ) : usages?.initialValue === null &&
                  usages?.passType === appPassTypeID.DAY_FLAT_UNLIMITED ? (
                  <div className="justify-content-center text-center circlular-progress-inner">
                    <img
                      className="unlimited-img mx-auto mt-2 mb-1"
                      src={unlimited}
                      alt="unlimited"
                    />
                    <h6
                      className="text-center unlimited-heading"
                      dangerouslySetInnerHTML={{ __html: t('ek_dashboard_dayflat_volume') }}
                    />
                    {leftTime ? (
                      <>
                        {leftTime <= 0 && leftHours <= 0 && leftMins <= 0 ? (
                          <div
                            className="details-expiry px-1"
                            dangerouslySetInnerHTML={{ __html: t('ek2_dashboard_time_over') }}
                          />
                        ) : (
                          <>
                            <div
                              className="details-expiry px-1"
                              dangerouslySetInnerHTML={{ __html: t('ek2_dashboard_left_day') }}
                            />
                            {leftTime <= 0 ? (
                              leftHours <= 0 ? (
                                <div
                                  className="details-expiry px-1"
                                  dangerouslySetInnerHTML={{
                                    __html: `${leftMins} ${leftMins > 1 ? 'Minuten' : 'Minute'}`
                                  }}
                                />
                              ) : (
                                <div
                                  className="details-expiry px-1"
                                  dangerouslySetInnerHTML={{
                                    __html: `${leftHours} ${leftHours > 1 ? 'Stunden' : 'Stunde'}`
                                  }}
                                />
                              )
                            ) : (
                              <div
                                className="details-expiry px-1"
                                dangerouslySetInnerHTML={{
                                  __html: `${leftTime} ${leftTime > 1 ? 'Tage' : 'Tag'}`
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                ) : (
                  <div className="justify-content-center text-center">
                    <h6 className="font-blue" style={{ fontSize: '16px' }}>
                      {t('ek_dashboard_no_data_booked')}
                    </h6>
                  </div>
                )}
              </CircularProgressbarWithChildren>
            </div>
          )
        ) : null}

        <div className="justify-content-center text-center btn-details pb-1">
          {item?.id ? (
            <Button
              buttonClass={appButtonType.LINK}
              onClick={() => {
                navigate(appRoute.BOOKABLE_DATA);
              }}>
              <div className="plus-icon me-2">
                <i className="bi bi-plus-lg" />
              </div>
              {t('ek_dashboard_book-data')}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default PackDetailCard;
