/* eslint-disable no-unneeded-ternary */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import * as Yup from 'yup';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAlphaComm } from '@dom-digital-online-media/dom-alphacomm-sdk';
import {
  DEFAULT_FIELD_VALIDATION_TYPE,
  generateParamsForAddToCart,
  useAppConfig,
  getTopUpWithoutAmount
} from '@dom-digital-online-media/dom-app-config-sdk';

import {
  appAlert,
  appAutoTopUpPeriodType,
  appAutoTopUpType,
  appPaymentProductType,
  formValidation,
  appStorage,
  gettingNumberForGerman,
  AutoTopUpFor,
  appPaymentMethods,
  appTopUpType,
  appTopUpTo,
  appRoute
} from '@utils/globalConstant';
// getLowBalanceTopUp,
import { useAlert } from '@context/Utils/Alert';
import { useCustomer } from '@context/MobileOne/Customer';
// import { useLoader } from '@context/Utils';

export const OnlineTopUpContext = createContext();

export const OnlineTopUpProvider = function ({ children, config }) {
  // Constant
  const autoTopupFormInitialValue = {
    type: appAutoTopUpType.AUTOMATIC,
    isDiffNumber: 'false',
    number: '',
    isDiffNumberSelected: false,
    periodType: '',
    certainAmount: '',
    periodDay: '',
    amount: '',
    otherAmount: '',
    productId: '',
    productType: '',
    productAmount: '',
    isDirectProduct: 'NO',
    terms: false
  };

  const directTopupFormInitialValue = {
    type: appAutoTopUpType.UNIQUE,
    isDiffNumber: 'false',
    number: '',
    isDiffNumberSelected: '',
    amount: '',
    otherAmount: '',
    productId: '',
    productType: '',
    productAmount: '',
    isDirectProduct: 'NO'
  };

  // Context
  const { storage } = config;
  const { t } = useStaticContent();
  const { isUserLoggedIn } = useAuth();
  const { showAlert } = useAlert();
  const [queryParams] = useSearchParams();
  // const { showLoader, hideLoader } = useLoader();
  const {
    onSsoPaymentToken,
    onPaymentProduct,
    onPaymentAddToCart,
    onPaymentMethod,
    onPaymentCheckoutAddress,
    onCheckoutPaymentStart,
    onPaymentToken,
    onPaymentCommitOrder,
    onPaymentTokenDelete,
    onPaymentAutoTopupDelete,
    onPaymentAutoTopupQuery
  } = useAlphaComm();
  const { env } = useAppConfig();
  const { customerData, personalData, getCustomerData } = useCustomer();
  // State
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [topUpForm, setTopUpForm] = useState({
    isDirectProduct: 'NO',
    type: appTopUpType.AUTOMATIC, // appTopUpType // AUTOMATIC || DIRECT
    periodType: '', // appAutoTopUpPeriodType.LOW_BALANCE, // appAutoTopUpPeriodType.LOW_BALANCE, // LOW_BALANCE | ONCE_PER_MONTH | RATE
    // periodDay: '', // Only for  ONCE_PER_MONTH,
    chargeTo: appTopUpTo.SELF, // appTopUpTo.SELF,
    phone: '',
    // confirmPhone: '',
    productId: '',
    productType: '',
    productAmount: '', // Only for DIRECT,
    paymentMethodName: '', // appPaymentMethods
    terms: false
  });

  const [paymentProducts, setPaymentProducts] = useState([]);
  const [directProducts, setDirectProducts] = useState([]);
  const [allPaymentProducts, setAllPaymentProducts] = useState([]);
  const [currentTopupType, setCurrentTopupType] = useState(null);
  const [autoTopUpQueryList, setAutoTopUpQueryList] = useState([]);

  const [cartName, setCartName] = useState(null); // Cart ID / Name when added to card.
  const [paymentAuthData, setPaymentAuthData] = useState({});
  const [alphaComToken, setAlphaComToken] = useState('');
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [allPaymentMethod, setAllPaymentMethod] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [ip, setIp] = useState('');
  const [deleteTopupSuccessModal, setDeleteTopupSuccessModal] = useState(false);

  const [personalInfoForm, setPersonalInfoForm] = useState({
    cartName: '',
    email: '',
    firstName: '',
    lastName: '',
    street: '',
    houseNumber: '',
    zip: '',
    city: '',
    phoneNumber: '',
    ipAddress: '203.88.141.230',
    countryCode: 'DE',
    language: 'de'
  });

  const [paymentMethodForm, setPaymentMethodForm] = useState({
    paymentMethod: ''
  });

  const [paymentTokens, setPaymentTokens] = useState({});
  const [autoTopupForm, setAutoTopupForm] = useState(autoTopupFormInitialValue);
  const [directTopupForm, setDirectTopupForm] = useState(directTopupFormInitialValue);
  const [isAutoTopUpActive, setIsAutoTopUpActive] = useState(false);
  const [currentTopupDate, setCurrentTopupDate] = useState('');
  const [isAutoLowEnabled, setIsAutoLowEnabled] = useState(false);
  const [autoTopupMoreInfo, setAutoTopupMoreInfo] = useState(false);

  // Validation
  const topUpValidations = Yup.lazy((values) => {
    // values is the formik.values object
    if (values.type === appAutoTopUpType.AUTOMATIC) {
      if (values.periodType === appAutoTopUpPeriodType.ONCE_PER_MONTH) {
        return Yup.object().shape({
          type: formValidation({ required: 'please_select_type' }),
          periodType: formValidation({ required: 'please_select_period_type' }),
          periodDay: formValidation({ required: 'please_select_period_day' }),
          phone: formValidation({ required: 'please_enter_phone' }),
          confirmPhone: formValidation({ required: 'please_enter_confirm_phone' }).oneOf(
            [Yup.ref('phone'), null],
            'please_match_phone'
          ),
          productId: formValidation({ required: 'please_select_product' })
        });
      }
      return Yup.object().shape({
        type: formValidation({ required: 'please_select_type' }),
        periodType: formValidation({ required: 'please_select_period_type' }),
        phone: formValidation({ required: 'please_enter_phone' }),
        confirmPhone: formValidation({ required: 'please_enter_confirm_phone' }).oneOf(
          [Yup.ref('phone'), null],
          'please_match_phone'
        ),
        productId: formValidation({ required: 'please_select_product' })
      });
    }
    return Yup.object().shape({
      type: formValidation({ required: 'please_select_type' }),
      phone: formValidation({ required: 'please_enter_phone' }),
      confirmPhone: formValidation({ required: 'please_enter_confirm_phone' }).oneOf(
        [Yup.ref('phone'), null],
        'please_match_phone'
      ),
      productId: formValidation({ required: 'please_select_product' })
    });
  });

  const personalInfoValidations = Yup.object().shape({
    cartName: formValidation({ required: 'please_cartName' }),
    email: formValidation({
      required: 'please_enter_email',
      regex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      validErrorMessage: 'please_valid_email'
    }),
    firstName: formValidation({
      required: 'please_enter_first_name',
      regex: /^[A-Za-zÄÖÜßäöü-\s]{2,25}$/,
      validErrorMessage: 'please_valid_first_name'
    }),
    lastName: formValidation({
      required: 'please_enter_last_name',
      regex: /^[A-Za-zÄÖÜßäöü-\s]{2,25}$/,
      validErrorMessage: 'please_valid_last_name'
    }),
    street: formValidation({
      required: 'please_enter_street',
      regex: /^.{0,100}$/,
      validErrorMessage: 'please_valid_street'
    }),
    houseNumber: formValidation({
      required: 'please_enter_house_number',
      regex: /^[A-Za-z0-9ÄÖÜßäöü.-]{0,25}$/,
      validErrorMessage: 'please_valid_house_number'
    }),
    city: formValidation({
      required: 'please_enter_city',
      regex: /^.{0,75}$/,
      validErrorMessage: 'please_valid_city'
    }),
    zip: formValidation({
      required: 'please_enter_post_code',
      regex: /^[0-9]{0,25}$/,
      validErrorMessage: 'please_valid_post_code'
    }),
    phoneNumber: formValidation({
      required: 'please_enter_phone_number',
      regex: /^[0-9-+]{4,15}$/,
      validErrorMessage: 'please_valid_phone_number'
    }),
    ipAddress: formValidation({ required: 'please_ipAddress' }),
    countryCode: formValidation({ required: 'please_countryCode' }),
    language: formValidation({ required: 'please_language' })
  });

  const paymentValidations = Yup.object().shape({
    paymentMethod: formValidation({
      required: 'please_select_paymentMethod',
      type: DEFAULT_FIELD_VALIDATION_TYPE.STRING
    })
  });

  // const TopupNumberValidation = Yup.object().shape({
  //   isDiffNumberSelected: formValidation({
  //     required: t('please_select_option_to_continue')
  //   }),
  //   number: formValidation({}).when('isDiffNumberSelected', {
  //     is: (value) => value === 'true',
  //     then: formValidation({
  //       required: t('ek_autotop-up_setup_error-msg'),
  //       regex: /^\d{5,20}$/,
  //       validErrorMessage: t('ek_autotop-up_setup_error-msg')
  //     })
  //   })
  // });

    const TopupNumberValidation = Yup.object().shape({
      isDiffNumberSelected: formValidation({
        required: t('ek_topup_selected-number_error-msg')
      }),
      number: formValidation({}).when('isDiffNumberSelected', {
        is: (value) => value === 'true',
        then: formValidation({
          required: t('ek_autotop-up_setup_error-msg'),
          regex: /^\d{5,20}$/,
          validErrorMessage: t('ek_autotop-up_setup_error-msg')
        })
      })
    });

  const autoTopUpValidations = Yup.lazy((values) => {
    // values is the formik.values object
    if (values.isDiffNumberSelected === true) {
    }
  });

  const autoTopupOptionValidation = Yup.object().shape({
    periodType: formValidation({
      required: t('please_select_option_to_continue')
    }),
    // certainAmount: formValidation({}).when('periodType', {
    //   is: (value) => value === appAutoTopUpPeriodType.LOW_BALANCE,
    //   then: formValidation({
    //     required: t('ek_autotop-up_setup_option1')
    //   })
    // }),
    periodDay: formValidation({}).when('periodType', {
      is: (value) => value === appAutoTopUpPeriodType.ONCE_PER_MONTH,
      then: formValidation({
        required: t('ek_autotop-up_setup_option2')
      })
    })
  });

  const directAmountValidation = Yup.lazy((values) => {
    // console.log('values--', values);
    if (values && values.isDirectProduct) {
      if (values.isDirectProduct === 'YES') {
        return Yup.object().shape({
          productId: formValidation({
            required: t('please_select_direct_amount_to_continue')
          }),
          otherAmount: formValidation({
            required: t('ek_autotop-other_amount_err'),
            regex: /^([1-9]|[1-9][0-9]|100)$/,
            validErrorMessage: t('ek_autotop-other_amount_err')
          })
        });
      }
      return Yup.object().shape({
        productId: formValidation({
          required: t('please_select_direct_amount_to_continue')
        })
      });
    }
    return Yup.object().shape({
      productId: formValidation({
        required: t('please_select_direct_amount_to_continue')
      })
    });
  });

  // const directAmountValidation = Yup.object().shape({
  //   productId: formValidation({
  //     required: t('please_select_direct_amount_to_continue')
  //   }),
  //   otherAmount: formValidation({}).when('isDirectProduct', {
  //     is: (value) => value === 'YES',
  //     then: formValidation({
  //       required: t('ek_autotop-other_amount_err'),
  //       // regex: /^\d{1,3}$/,
  //       // validErrorMessage: t('ek_autotop-other_amount_err')
  //     })
  //   })
  // });

  useEffect(() => {
    const { msisdn } = customerData;
    const {
      email: { emailAddress = '' } = {},
      firstName,
      lastName,
      address: { street = '', houseNumber = '', city = '', zip = '' } = {}
    } = personalData;
    if (msisdn && emailAddress) {
      const phone = msisdn.charAt(0) === '0' ? msisdn.substring(1) : msisdn;
      setTopUpForm({
        ...topUpForm,
        phone: `+49${phone}`,
        confirmPhone: `+49${phone}`
      });
      setPersonalInfoForm({
        ...personalInfoForm,
        phoneNumber: `+49${phone}`,
        email: emailAddress,
        firstName,
        lastName,
        street,
        houseNumber,
        city,
        zip
      });
    }
  }, [customerData, personalData]);

  // const topUpValidations = Yup.object().shape({
  //   type: formValidation({ required: 'please_select_type' }),
  //   periodType: formValidation({ required: 'please_select_period_type' }),
  //   // periodDay: formValidation(),
  //   phone: formValidation({ required: 'please_enter_phone' }),
  //   confirmPhone: formValidation({ required: 'please_enter_confirm_phone' }),
  //   // productId: formValidation(),
  //   // productType: formValidation(),
  //   // productAmount: formValidation(),
  //   // cartName: formValidation(),
  //   // ipAddress: formValidation(),
  //   // orderNumber: formValidation()
  //   // voucherCode: formValidation({
  //   //   required: 'please_select_paymentMethod',
  //   //   type: DEFAULT_FIELD_VALIDATION_TYPE.STRING,
  //   //   minLength: 13,
  //   //   minLengthError: 'min_length_error',
  //   //   maxLength: 16,
  //   //   maxLengthError: 'max_length_error'
  //   // })
  // });

  // Function

  // Stepper Functions
  const nextStep = () => setCurrentStep(currentStep + 1);
  const prevStep = () => setCurrentStep(currentStep - 1);

  // get Ip Address
  const getIp = async () => {
    const ipResponse = await axios.get(`${env.REACT_APP_SERVER}api/ip/address`);
    if (ipResponse) {
      setIp(ipResponse.data.ip);
    }
  };

  const getOrderStatus = async () => {
    try {
      const queryParamsData = {
        paymentToken: queryParams.get('paymentTokenId'),
        orderNumber: queryParams.get('orderNumber'),
        cartName: queryParams.get('cartName')
      };

      if (queryParamsData.paymentToken || queryParamsData.orderNumber || queryParamsData.cartName) {
        const { data = {}, success } = await onPaymentCommitOrder(queryParamsData);
        if (data && success) {
          showAlert({ type: appAlert.SUCCESS, message: 'Payment Successfull..' });
          sessionStorage.removeItem(appStorage.CART_NAME);
          sessionStorage.removeItem(appStorage.ORDER_NUMBER);
        } else {
          showAlert({ type: appAlert.DANGER, message: 'Payment UnSuccessfull..' });
          sessionStorage.removeItem(appStorage.CART_NAME);
          sessionStorage.removeItem(appStorage.ORDER_NUMBER);
        }
      }
      return true;
    } catch (error) {
      console.log(error);
      sessionStorage.removeItem(appStorage.CART_NAME);
      sessionStorage.removeItem(appStorage.ORDER_NUMBER);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  // Payment Products
  const getPaymentProducts = async () => {
    // setIsLoading(true);
    try {
      let directProduct = [];
      let advanceProducts = [];

      const {
        data: { items = [] }
      } = await onPaymentProduct();
      if (items.length > 0) {
        const fixedProducts = items.filter((product) => product.minAmount === product.maxAmount);
        const directProducts = items.filter((product) => product.minAmount !== product.maxAmount);
        // TODO: Update products with selected type.
        // setPaymentProducts(items);
        // if (env.REACT_APP_T_PAY_DIRECT_ID) {
        //   advanceProducts.push({
        //     id: Number(env.REACT_APP_T_PAY_DIRECT_ID),
        //     parentId: 887187,
        //     objectType: 'object',
        //     productType: 'PREPAID',
        //     optionType: 'autoTopup',
        //     minAmount: 0.01,
        //     maxAmount: 0.01,
        //     currency: 'EUR',
        //     amount: 0.01
        //   });
        // }
        if (env.REACT_APP_T_PAY_AUTO_LOW_ID) {
          advanceProducts.push({
            id: Number(env.REACT_APP_T_PAY_AUTO_LOW_ID),
            parentId: 887187,
            objectType: 'object',
            productType: 'LOW_BALANCE',
            optionType: 'autoTopup',
            minAmount: 0.01,
            maxAmount: 0.01,
            currency: 'EUR',
            amount: 0.01
          });
        }

        fixedProducts.forEach((product) => {
          advanceProducts.push(product);
        });

        directProducts.forEach((product) => {
          advanceProducts.push(product);
        });

        setPaymentProducts([...advanceProducts]);
        // ...items.filter(
        //   ({ productType }) =>
        //     productType === appPaymentProductType.DIRECT ||
        //     productType === appPaymentProductType.PREPAID ||
        //     productType === appPaymentProductType.LOW_BALANCE
        // )

        // setPaymentProducts(
        //   items.filter(
        //     ({ productType }) =>
        //       productType === appPaymentProductType.DIRECT ||
        //       productType === appPaymentProductType.PREPAID
        //   )
        // );

        // allDirectProducts = items.filter(
        //   ({ productType }) => productType === appPaymentProductType.PREPAID
        // );

        // allDirectProducts.push(directProduct[0]);

        // setDirectProducts(allDirectProducts);
        setAllPaymentProducts(items);
      }
      // setIsLoading(false);
      return items;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  const getAutoTopUpData = async () => {
    try {
      setIsLoading(true);
      const { data, success } = await onPaymentToken();

      if (success && data) {
        setPaymentTokens(data?.paymentTokens.reverse());
      } else {
        setPaymentTokens([]);
      }
      setIsLoading(false);
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  const getPaymentMethods = async () => {
    try {
      const {
        data: { paymentMethods = {} }
      } = await onPaymentMethod();
      // eslint-disable-next-line prefer-const
      let payments = [];
      if (paymentMethods) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(paymentMethods)) {
          // console.log({ productType, value });

          payments.push({ name: key, ...value });
          if (key === appPaymentMethods.CREDIT_CARD) {
            payments.push({ name: appPaymentMethods.AMERICAN_EXPRESS, ...value });
          }
        }
        // console.log({ payments });
        setPaymentMethod(payments.filter((p) => p.paymentTokenEnabled));
        setAllPaymentMethod(payments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onAutoTopupQuerycall = async () => {
    try {
      // setIsLoading(true);
      const { data, success } = await onPaymentAutoTopupQuery({
        autoTopupTypes: [appPaymentProductType.LOW_BALANCE, appPaymentProductType.RECURRING],
        statuses: ['ENABLED']
      });
      if (data && success) {
        if (data?.rows?.length > 0) {
          setAutoTopUpQueryList(data?.rows);
          setCurrentTopupType(data?.rows[0]?.autoTopupType);
          setCurrentTopupDate(data?.rows[0]?.created);
          setIsAutoTopUpActive(true);
        } else {
          setAutoTopUpQueryList([]);
        }
      } else {
        setAutoTopUpQueryList([]);
      }
      // setIsLoading(false);
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  // Payment Authentication
  const ssoPaymentToken = async ({ signupId, alphacommHash, msisdn }) => {
    try {
      const ssoPaymentTokenParams = {
        clientId: signupId,
        hashCode: alphacommHash,
        language: 'de',
        msisdn,
        pageId: 'home'
      };
      // setIsLoading(true);
      // showLoader();
      const { data = {}, success } = await onSsoPaymentToken(ssoPaymentTokenParams);
      if (data && success) {
        setAlphaComToken(data.token);
        setPaymentAuthData(ssoPaymentTokenParams);
        // await getAutoTopUpData();
        // await onAutoTopupQuerycall();
        setTimeout(() => {
          getPaymentProducts();
        }, 100);
      }
      // setIsLoading(false);
      // hideLoader();
      return data;
    } catch (error) {
      setIsLoading(false);
      // hideLoader();
      console.log('sso payment ---', error);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  const onDirectProductSubmit = async (values) => {
    try {
      // showLoader();
      // eslint-disable-next-line no-unused-vars
      const { otherAmount, productId } = values;

      const selectedProduct = allPaymentProducts.find((p) => p.id === Number(productId));

      if (selectedProduct) {
        // let
      }
      return true;
    } catch (error) {
      console.log(error);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody)
      });
      return false;
    }
  };

  const onCheckoutTopup = async ({ values, cartNameRes, productType }) => {
    try {
      setIsLoading(true);
      const inputParams = {
        cartName: cartNameRes,
        deliveryAddress: {
          email: values.email,
          firstname: values.firstName,
          lastname: values.lastName,
          street: values.street,
          houseNumber: values.houseNum,
          zip: values.zip,
          city: values.city,
          countryCode: 'DE',
          phoneNumber: values.number,
          // ipAddress: ip ? ip : '0.0.0.0',
          ipAddress: '203.88.141.230',
          language: 'de'
        }
      };

      const { success } = await onPaymentCheckoutAddress(inputParams);

      if (success) {
        const {
          data: { paymentMethods = {} }
        } = await onPaymentMethod();
        // eslint-disable-next-line prefer-const
        let payments = [];
        if (paymentMethods) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(paymentMethods)) {
            // console.log({ productType, value });
            if (
              ((productType === appPaymentProductType.RECURRING ||
                productType === appPaymentProductType.LOW_BALANCE) &&
                value.paymentTokenEnabled) ||
              ((productType === appPaymentProductType.DIRECT ||
                productType === appPaymentProductType.PREPAID) &&
                value.onceOffEnabled)
            ) {
              payments.push({ name: key, ...value });
              if (key === appPaymentMethods.CREDIT_CARD) {
                payments.push({ name: appPaymentMethods.AMERICAN_EXPRESS, ...value });
              }
            }
          }
          // console.log({ payments });
          setPaymentMethod(payments);
          setDeliveryAddress(inputParams.deliveryAddress);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        showAlert({
          type: appAlert.ERROR,
          message: `Error in request.`
        });
        setIsLoading(false);
      }
      return true;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  const onStepTwoSubmit = async (values) => {
    try {
      setIsLoading(true);
      setPaymentMethod([]);
      // console.log('values--', values);
      const { otherAmount, productId, productType } = values;

      const selectedProduct = allPaymentProducts.find((p) => p.id === Number(productId));

      if (selectedProduct) {
        // const { minAmount = 5 } = allPaymentProducts.find(
        //   ({ productType, amount }) =>
        //     (productType === appPaymentProductType.DIRECT ||
        //       productType === selectedProduct.productType) &&
        //     !amount
        // );

        // eslint-disable-next-line prefer-const
        let data = {
          productId: selectedProduct.id,
          option: {
            phoneNumber: values.number
              ? gettingNumberForGerman(values.number)
              : gettingNumberForGerman(customerData.msisdn), // simActivationDetails?.activationData?.msisdn,
            optionType: selectedProduct.optionType,
            // amount: values.productAmount || selectedProduct.amount,
            lowBalanceOption: {
              lowBalanceLimit:
                values.periodType === appAutoTopUpPeriodType.LOW_BALANCE
                  ? 5
                  : getTopUpWithoutAmount(values.type, values.periodType, allPaymentProducts)
                      .minAmount,
              tariffEnabled: isAutoLowEnabled
            },
            recurringOption: {}
          }
        };

        // eslint-disable-next-line prefer-const
        let days = {
          value: ''
        };

        if (values.periodDay) {
          days.value = values.periodDay;
        }

        //   // TODO: Refactor with proper fix
        const { type } = values;
        const inputParams = generateParamsForAddToCart(
          type,
          values.otherAmount,
          data,
          values.periodType,
          values.otherAmount ? '' : selectedProduct.amount.toString(),
          values.periodDay !== '' ? days : '',
          isAutoLowEnabled
        );

        const topupAmount = otherAmount ? otherAmount : selectedProduct.amount.toString();

        const {
          data: { cartName: responseCartName = false },
          success
        } = await onPaymentAddToCart(inputParams);
        if (responseCartName && success) {
          setCartName(responseCartName);
          sessionStorage.setItem(appStorage.PAYMENT_REASON, AutoTopUpFor.LOGIN);
          if (type === appAutoTopUpType.UNIQUE) {
            setDirectTopupForm({ ...directTopupForm, otherAmount, productId });
            // sessionStorage.setItem(appStorage.TOPUP_AMOUNT, topupAmount);
            sessionStorage.setItem(appStorage.TOPUP_TYPE, appAutoTopUpType.UNIQUE);
          } else {
            setAutoTopupForm({ ...autoTopupForm, otherAmount, productId });
            sessionStorage.setItem(appStorage.TOPUP_TYPE, appAutoTopUpType.AUTOMATIC);
          }
          if (customerData && Object.keys(customerData).length > 0) {
            // Creating the object to pass in API
            // eslint-disable-next-line prefer-const
            let userData = {
              email: personalData?.email?.emailAddress,
              firstName: personalData?.firstName,
              lastName: personalData?.lastName,
              street: personalData?.address?.street,
              houseNum: personalData?.address?.houseNumber,
              zip: personalData?.address?.zip,
              city: personalData?.address?.city,
              country: personalData?.address?.countryName,
              number: gettingNumberForGerman(customerData.msisdn)
            };
            sessionStorage.setItem(appStorage.CART_NAME, responseCartName);
            // setTopUpForm({ ...topUpForm, cartName: responseCartName });
            setPersonalInfoForm({ ...personalInfoForm, cartName: responseCartName });
            // setIsLoading(false);
            // hideLoader();
            onCheckoutTopup({ values: userData, cartNameRes: responseCartName, productType });
          } else {
            showAlert({
              type: appAlert.ERROR,
              message: 'Bitter request processed. Try again later.'
            });
            setIsLoading(false);
          }
          // hideLoader();
        }
      }
      // setIsLoading(false);
      // hideLoader();
      return values;
    } catch (error) {
      setIsLoading(false);
      // hideLoader();
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  const onStepThreeSubmit = async (values) => {
    // Checkout address & getch payment methods & got to next step;
    try {
      setIsLoading(true);
      setPersonalInfoForm(values);

      const params = {
        cartName: values.cartName,
        deliveryAddress: {
          email: values.email,
          firstname: values.firstName,
          lastname: values.lastName,
          street: values.street,
          houseNumber: values.houseNumber,
          zip: values.zip,
          city: values.city,
          countryCode: 'DE',
          phoneNumber: values.phoneNumber,
          ipAddress: values.ipAddress,
          language: values.language
        }
      };

      const { data = {}, success } = await onPaymentCheckoutAddress(params);
      if (data && success) {
        const {
          data: { paymentMethods = {} }
        } = await onPaymentMethod();
        // eslint-disable-next-line prefer-const
        let payments = [];
        if (paymentMethods) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(paymentMethods)) {
            // console.log({ name: key, ...value });
            if (value.paymentTokenEnabled) {
              payments.push({ name: key, ...value });
            }
          }
          // console.log({ payments });
          setPaymentMethod(payments);
          setIsLoading(false);
          // hideLoader();
        }
        showAlert({ type: appAlert.SUCCESS, message: 'Checkout Verified Successfully..' });
        nextStep();
      }
      return data;
    } catch (error) {
      setIsLoading(false);
      // hideLoader();
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  const onStepFourSubmit = async (values) => {
    // Process payment methods & store all details in the storage;
    const paymentName =
      values.paymentMethod === appPaymentMethods.AMERICAN_EXPRESS
        ? appPaymentMethods.CREDIT_CARD
        : values.paymentMethod;
    try {
      setIsLoading(true);
      const inputParams = {
        cartName,
        paymentMethod: paymentName,
        returnUrl: env.REACT_APP_SHOPPING_RETURN_URL,
        savePayment: sessionStorage.getItem(appStorage.TOPUP_TYPE) === appAutoTopUpType.AUTOMATIC
      };
      const {
        data: { orderNumber, payerUrl },
        success
      } = await onCheckoutPaymentStart(inputParams);
      if (payerUrl && success) {
        setIsLoading(false);
        // hideLoader();
        // showAlert({ type: appAlert.SUCCESS, message: 'Please Process With The Payment..' });
        sessionStorage.setItem(appStorage.ORDER_NUMBER, orderNumber);
        window.open(payerUrl, '_self');
      }
      return { payerUrl };
    } catch (error) {
      setIsLoading(false);
      // hideLoader();
      console.log(error);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  const onDeleteConfirm = async (deleteAutoTopup) => {
    try {
      setIsLoading(true);
      const { success, data } = await onPaymentTokenDelete({
        id: deleteAutoTopup.id
      });

      // if (success && data) {
      //   // hideLoader();
      //   setDeleteTopupSuccessModal(true);
      // }
      await onSsoTokenCall();
      await getAutoTopUpData();
      setIsLoading(false);
      return true;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  const onDeleteAutoTopUp = async (deleteAutoTopup) => {
    try {
      setIsLoading(true);
      const { success, data } = await onPaymentAutoTopupDelete({
        autoTopupType: deleteAutoTopup.autoTopupType,
        brand: deleteAutoTopup.brand,
        autoTopupId: deleteAutoTopup.autoTopupId
      });

      if (success && data) {
        // hideLoader();
        setDeleteTopupSuccessModal(true);
      }
      // hideLoader();
      await onSsoTokenCallWithQuery();
      setIsLoading(false);
      return true;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      return false;
    }
  };

  const onAddToCart = async (values) => {
    try {
      const { chargeTo, phone, productAmount, productId, type, isDirectProduct } = values;

      const selectedProduct = paymentProducts.find((p) => p.id === Number(productId));

      let option = {};

      if (type === appTopUpType.AUTOMATIC) {
        option = {
          phoneNumber:
            chargeTo === appTopUpTo.SELF
              ? gettingNumberForGerman(customerData.msisdn) // simActivationDetails?.activationData?.msisdn,
              : gettingNumberForGerman(phone),
          optionType: selectedProduct.optionType,
          amount: productAmount || selectedProduct.amount,
          lowBalanceOption: {
            lowBalanceLimit: 5,
            tariffEnabled: true
          }
        };
      }

      if (type === appTopUpType.DIRECT) {
        option = {
          phoneNumber:
            chargeTo === appTopUpTo.SELF
              ? gettingNumberForGerman(customerData.msisdn)
              : gettingNumberForGerman(phone),
          optionType: selectedProduct.optionType
        };

        if (isDirectProduct === 'YES') {
          option.amount = productAmount || selectedProduct.amount;
        }
      }

      const data = {
        items: [
          {
            productId: selectedProduct.id,
            option
          }
        ]
      };

      return await onPaymentAddToCart(data);
    } catch (error) {
      console.log('ADD_TO_CART_ERROR:', error);
      throw error;
    }
  };

  const onCheckoutAddress = async (values, _cartName) => {
    try {
      // console.log('values-------------', values);
      const { chargeTo, phone, productAmount, productId, type } = values;

      const selectedProduct = paymentProducts.find((p) => p.id === Number(productId));

      // console.log('selectedProduct-----------------', selectedProduct);

      await storage.setItem(appStorage.TOPUP_AMOUNT, selectedProduct.amount || productAmount);
      await storage.setItem(appStorage.TOPUP_TYPE, type);
      // if (customerData && Object.keys(customerData).length > 0) {
      // Creating the object to pass in API
      // eslint-disable-next-line prefer-const

      await storage.setItem(appStorage.CART_NAME, _cartName);
      setTopUpForm({ ...topUpForm, cartName: _cartName });
      const checkoutAddressPayload = {
        cartName: _cartName,
        deliveryAddress: {
          email: personalData?.email?.emailAddress,
          firstname: personalData?.firstName,
          lastname: personalData?.lastName,
          street: personalData?.address?.street,
          houseNumber: personalData?.address?.houseNumber,
          zip: personalData?.address?.zip,
          city: personalData?.address?.city,
          countryCode: 'DE',
          phoneNumber:
            chargeTo === appTopUpTo.SELF
              ? gettingNumberForGerman(customerData.msisdn)
              : gettingNumberForGerman(phone),
          ipAddress: ip ? ip : '0.0.0.0',
          language: 'de'
        }
      };
      return await onPaymentCheckoutAddress(checkoutAddressPayload);
    } catch (error) {
      console.log('CHECKOUT_ADDRESS_ERROR:', error);
      throw error;
    }
  };

  const onCheckoutStartPayment = async (values, _cartName) => {
    try {
      const { type, paymentMethodName } = values;

      // AMEX is not a different method its just a credit card
      const paymentName =
      paymentMethodName === appPaymentMethods.AMERICAN_EXPRESS
        ? appPaymentMethods.CREDIT_CARD
        : paymentMethodName;

      const inputParams = {
        cartName: _cartName,
        paymentMethod: paymentName,
        returnUrl: env.REACT_APP_SHOPPING_RETURN_URL,
        savePayment: type === appTopUpType.AUTOMATIC
      };
      return await onCheckoutPaymentStart(inputParams);
    } catch (error) {
      console.log('START_PAYMENT_ERROR:', error);
      throw error;
    }
  };

  const onAutoTopupSubmit = async (values) => {
    try {
      setIsLoading(true);
      // console.log('Step Two Submit', values);
      setTopUpForm({
        ...topUpForm,
        ...values
      });
      const {
        data: { cartName: responseCartName = false },
        success: successAddToCart
      } = await onAddToCart(values);

      if (responseCartName && successAddToCart) {
        setCartName(responseCartName);
        const { success: successCheckoutAddress } = await onCheckoutAddress(
          values,
          responseCartName
        );
        if (successCheckoutAddress) {
          const {
            data: { orderNumber, payerUrl },
            success
          } = await onCheckoutStartPayment(values, responseCartName);
          if (payerUrl && success) {
            await storage.setItem(appStorage.PAYMENT_REASON, AutoTopUpFor.LOGIN);
            if (values.type === appTopUpType.DIRECT) {
              const selectedProduct = allPaymentProducts.find(
                (p) => p.id === Number(values.productId)
              );
              // console.log('selectedProduct==================', selectedProduct);
              // await storage.setItem(appStorage.TOPUP_AMOUNT, selectedProduct.amount);
              await storage.setItem(appStorage.TOPUP_TYPE, appTopUpType.DIRECT);
            } else {
              // await storage.setItem(appStorage.TOPUP_AMOUNT, topupAmount);
              await storage.setItem(appStorage.TOPUP_TYPE, appTopUpType.AUTOMATIC);
            }
            await storage.setItem(appStorage.ORDER_NUMBER, orderNumber);
            window.open(payerUrl, '_self');
            setIsLoading(false);
          }
        } else {
          // TODO: Show generic error
          setIsLoading(false);
          showAlert({
            type: appAlert.ERROR,
            message: t('ek_generic_err_title')
          });
          setIsLoading(false);
        }
      } else {
        // TODO: Show generic error
        setIsLoading(false);
        showAlert({
          type: appAlert.ERROR,
          message: t('ek_generic_err_title')
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      showAlert({
        type: appAlert.ERROR,
        message: t(error?.error[0]?.messageBody || 'ek_generic_err_title')
      });
    }
    // const {
    //   isDiffNumberSelected,
    //   periodType,
    //   number,
    //   certainAmount,
    //   periodDay,
    //   productId,
    //   otherAmount
    // } = values;
    // if (isDiffNumberSelected !== '') {
    //   setAutoTopupForm({ ...autoTopupForm, isDiffNumberSelected, number });
    // }

    // if (periodType !== '') {
    //   setAutoTopupForm({ ...autoTopupForm, periodType });
    //   if (periodType === appAutoTopUpPeriodType.LOW_BALANCE) {
    //     setAutoTopupForm({ ...autoTopupForm, periodType, certainAmount });
    //   } else if (periodType === appAutoTopUpPeriodType.ONCE_PER_MONTH) {
    //     setAutoTopupForm({ ...autoTopupForm, periodType, periodDay });
    //   }
    // }

    // if (productId !== '') {
    //   setAutoTopupForm({ ...autoTopupForm, productId });
    //   if (productId === 'otherAmount') {
    //     setAutoTopupForm({ ...autoTopupForm, otherAmount, productId });
    //   }
    // }
  };

  const onDirectTopupSubmit = (values) => {
    const { isDiffNumberSelected, number } = values;

    if (isDiffNumberSelected !== '') {
      setDirectTopupForm({ ...directTopupForm, isDiffNumberSelected, number });
    }
  };

  const onLoad = async () => {
    try {
      // TODO: Exten it with api call to fetch all voucher history.
      console.log('Loading AlphaComm Online TopUp');
      setIsLoading(true);
      await getCustomerData();
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = async (values) => {
    console.log(values);
    await onStepTwoSubmit(values);
  };

  const topupOnLoad = () => {
    setAutoTopupForm(autoTopupFormInitialValue);
    setDirectTopupForm(directTopupFormInitialValue);
  };

  const onSsoTokenCall = async () => {
    const { alphacommHash, msisdn, signupId } = customerData;
    if (alphacommHash && msisdn && signupId) {
      setIsLoading(true);
      await ssoPaymentToken({ alphacommHash, msisdn, signupId });
      setIsLoading(false);
    }
  };

  const onSsoTokenCallWithQuery = async () => {
    const { alphacommHash, msisdn, signupId } = customerData;
    if (alphacommHash && msisdn && signupId) {
      setIsLoading(true);
      await ssoPaymentToken({ alphacommHash, msisdn, signupId });
      await onAutoTopupQuerycall();
      setIsLoading(false);
    }
  };

  // Hooks
  // useEffect(() => {
  //   if (isUserLoggedIn && customerData) {
  //     const { alphacommHash, msisdn, signupId } = customerData;
  //     if (alphacommHash && msisdn && signupId) {
  //       setIsLoading(true);
  //       ssoPaymentToken({ alphacommHash, msisdn, signupId });
  //       // onAutoTopupQuerycall();
  //     }
  //   }
  //   return () => {
  //     // setHistory([]);
  //   };
  // }, [isUserLoggedIn, customerData]);

  useEffect(() => {
    getIp();
  }, []);

  // useEffect(() => {
  //   if (isUserLoggedIn && alphaComToken) {
  //     onAutoTopupQuerycall();
  //   }
  // }, [alphaComToken]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // Constants
      autoTopupFormInitialValue,
      directTopupFormInitialValue,

      // States
      isLoading,
      setIsLoading,
      currentStep,
      setCurrentStep,
      topUpForm,
      setTopUpForm,
      personalInfoForm,
      setPersonalInfoForm,
      personalInfoValidations,

      paymentProducts,
      setPaymentProducts,
      allPaymentProducts,
      setAllPaymentProducts,
      cartName,
      setCartName,
      paymentAuthData,
      setPaymentAuthData,

      isAutoTopUpActive,

      autoTopupMoreInfo,
      setAutoTopupMoreInfo,

      paymentMethod,
      paymentMethodForm,
      setPaymentMethodForm,

      deliveryAddress,
      setDeliveryAddress,

      deleteTopupSuccessModal,
      setDeleteTopupSuccessModal,

      paymentTokens,
      setPaymentTokens,

      directProducts,
      setDirectProducts,

      currentTopupType,
      setCurrentTopupType,

      autoTopUpQueryList,
      setAutoTopUpQueryList,

      currentTopupDate,
      setCurrentTopupDate,

      isAutoLowEnabled,
      setIsAutoLowEnabled,

      autoTopUpValidations,
      autoTopupOptionValidation,
      directAmountValidation,
      autoTopupForm,
      setAutoTopupForm,

      directTopupForm,
      setDirectTopupForm,

      alphaComToken,
      setAlphaComToken,

      allPaymentMethod,
      setAllPaymentMethod,

      // Validations
      topUpValidations,
      paymentValidations,
      TopupNumberValidation,

      // Functions
      getPaymentProducts,
      getPaymentMethods,
      onAutoTopupSubmit,
      topupOnLoad,
      onDirectTopupSubmit,
      onDirectProductSubmit,
      onAutoTopupQuerycall,
      getAutoTopUpData,
      onLoad,
      getOrderStatus,
      onDeleteConfirm,
      onDeleteAutoTopUp,
      onSubmit,
      nextStep,
      prevStep,
      ssoPaymentToken,
      onSsoTokenCall,
      onSsoTokenCallWithQuery,
      onStepTwoSubmit,
      onStepThreeSubmit,
      onStepFourSubmit
    }),
    [
      // Constants
      autoTopupFormInitialValue,
      directTopupFormInitialValue,

      // States
      isLoading,
      setIsLoading,
      currentStep,
      setCurrentStep,

      topUpForm,
      setTopUpForm,
      personalInfoForm,
      setPersonalInfoForm,
      personalInfoValidations,

      paymentProducts,
      setPaymentProducts,
      allPaymentProducts,
      setAllPaymentProducts,

      cartName,
      setCartName,

      paymentAuthData,
      setPaymentAuthData,

      isAutoTopUpActive,

      autoTopupMoreInfo,
      setAutoTopupMoreInfo,

      directProducts,
      setDirectProducts,

      paymentMethod,
      paymentMethodForm,
      setPaymentMethodForm,

      deliveryAddress,
      setDeliveryAddress,

      deleteTopupSuccessModal,
      setDeleteTopupSuccessModal,

      paymentTokens,
      setPaymentTokens,

      currentTopupType,
      setCurrentTopupType,

      autoTopUpQueryList,
      setAutoTopUpQueryList,

      currentTopupDate,
      setCurrentTopupDate,

      isAutoLowEnabled,
      setIsAutoLowEnabled,

      autoTopUpValidations,
      autoTopupOptionValidation,
      directAmountValidation,
      autoTopupForm,
      setAutoTopupForm,

      directTopupForm,
      setDirectTopupForm,

      alphaComToken,
      setAlphaComToken,

      allPaymentMethod,
      setAllPaymentMethod,

      // Validations
      topUpValidations,
      paymentValidations,
      TopupNumberValidation,

      // Functions
      getPaymentProducts,
      getPaymentMethods,
      onAutoTopupSubmit,
      topupOnLoad,
      onDirectTopupSubmit,
      onDirectProductSubmit,
      onAutoTopupQuerycall,
      getAutoTopUpData,
      onLoad,
      getOrderStatus,
      onDeleteConfirm,
      onDeleteAutoTopUp,
      onSubmit,
      nextStep,
      prevStep,
      ssoPaymentToken,
      onSsoTokenCall,
      onSsoTokenCallWithQuery,
      onStepTwoSubmit,
      onStepThreeSubmit,
      onStepFourSubmit
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <OnlineTopUpContext.Provider value={contextPayload}>{children}</OnlineTopUpContext.Provider>
  );
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useOnlineTopUp = () => useContext(OnlineTopUpContext);

export default OnlineTopUpProvider;
